import React, { useEffect, useRef, useState } from "react";
import {
    Layer,
    Popup,
    FocusTrapZone,
    IconButton,
    DefaultButton,
    Icon,
    TooltipHost,
    DirectionalHint,
    PrimaryButton,
    Overlay,
    mergeStyleSets
} from "@fluentui/react";
import styles from "./DynamicTemplatePopup.module.css";
import { saveTemplate, loadTemplate, updateTemplate, deleteTemplate, makeFavouriteTemplate, makeUserFavouriteTemplate } from "../../api";
import "./TemplateEditor.css";
import i18next from "i18next";
import {
    Checkbox,
    Chip,
    DialogContent,
    DialogTitle,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Snackbar,
    SnackbarCloseReason,
    Tooltip,
    tooltipClasses,
    TooltipProps
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { userInfo, jwtToken } from "../../authConfig";
import { jwtDecode } from "jwt-decode";
import { setTemplateContentsInStore, setUserDefaultContentInStore, setDefaultTemplateIdInStore, setSelectedProductInStore } from "../../Store/TemplateContents";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { RootState } from "../../Store";
import Warning from "@mui/icons-material/Warning";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Alert from "@mui/material/Alert";

type Product = {
    manual_key: string;
    faq_key: string;
    text: string;
    data?: Product[];
};

type TokenValues = {
    isInchargeOfAiWorks: string;
    userSupportId: string;
};

export let userSupportId = "bot@ai-works";

interface ResponseTemplatePopupProps {
    hideDynamicTemplatePopup: boolean;
    selectedTemplate: number;
    handleTemplateIconClick: () => void;
    handleTemplateClick: (templateId: number) => void;
    handleTemplateContents: (contents: string, tempId: string) => void;
    products: Product[];
    selectedProduct: string;
    snackbar: () => void;
    setIsEdited: (isEdit: boolean) => void;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&::before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.3rem" }} />} {...props} />
))(({ theme }) => ({
    // backgroundColor: "#f5f5f5",
    fontSize: "1rem",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    },
    ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255, 255, 255, .05)"
    })
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

const DynamicTemplatePrompt: React.FC<ResponseTemplatePopupProps> = ({
    hideDynamicTemplatePopup,
    handleTemplateIconClick,
    handleTemplateClick,
    handleTemplateContents,
    products,
    selectedProduct,
    snackbar,
    setIsEdited
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isCopyAndCreateNew, setIsCopyAndCreateNew] = useState(false);
    const [templateContents, setTemplateContents] = useState<any>([
        {
            id: "",
            pk: "",
            product: "",
            template: [{ title: "", prompt: "", fields: {}, previewPrompt: "", isSystemTemplate: false, isDefault: false }],
            useCount: 0
        }
    ]);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [editingTemplate, setEditingTemplate] = useState<string>("");
    const [selectedTemplate, setSelectedTemplate] = useState(Number);
    const [shouldRerender, setShouldRerender] = useState(false);
    const [userPromptValues, setUserPromptValues] = useState([
        { title: "", prompt: "", fields: {}, previewPrompt: "", isSystemTemplate: false, isDefault: false }
    ]);
    const [fields, setFields] = useState<any>({});
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = useState(false);
    const [ConfirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
    const [currentId, setCurrentId] = useState<string | undefined>("");
    const [templateProduct, setTemplateProduct] = useState<string>("");
    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackBgColor, setSnackBgColor] = useState("green");
    const [snackFontColor, setSnackFontColor] = useState("white");

    const [clickedItem, setClickedItem] = useState("");
    const [hoveredTemplate, setHoveredTemplate] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [showInfo, setShowInfo] = useState(false);
    const [isStarSelected, setIsStarSelected] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    let clickedTemplateTitle = useRef<string>("");
    let clickedTemplateIndex = useRef<Number>(0);
    let clickedTemplateId = useRef<string>("");
    let clickedTemplateEmailId = useRef<string>("");
    let clickedTemplateProduct = useRef<string>("");

    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    userSupportId = tokenValues["userSupportId"];
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";
    const [expandedPanel, setExpandedPanel] = useState<string | null>(null);

    const dispatch = useDispatch();
    const systemDefaultContent = useSelector((store: RootState) => store.templateContents.systemDefaultContent);

    useEffect(() => {
        if (selectedProduct === "ALL") {
            setExpandedPanel("ALL");
        } else {
            setExpandedPanel(null);
        }
    }, [selectedProduct]);

    useEffect(() => {
        if (selectedProduct === `${i18next.t("chat.productFilterText")}`) {
            return;
        }
        let active = true;
        const getTemplateFromDb = async () => {
            try {
                const response = await loadTemplate(selectedProduct);
                const data = await response.json();
                if (active) {
                    if (
                        data?.[selectedProduct]?.length > 0 &&
                        userInfo["userFavourite"][selectedProduct] === undefined &&
                        userInfo["userFavourite"].hasOwnProperty(selectedProduct) === false
                    ) {
                        userInfo["userFavourite"][selectedProduct] = data[selectedProduct][0]["id"];
                        makeUserFavouriteTemplate(data[selectedProduct][0]["id"], selectedProduct, false);
                    }
                    setTemplateContents(data);
                    dispatch(setTemplateContentsInStore(data["quickSearch"]));
                    dispatch(setSelectedProductInStore(selectedProduct));
                    setTemplateProduct(selectedProduct);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching template data: ", error);
                setLoading(false);
            }
        };
        getTemplateFromDb();
        setClickedItem("");
        return () => {
            active = false;
        };
    }, [shouldRerender, selectedProduct]);

    if (!hideDynamicTemplatePopup) {
        return null;
    }

    const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }

        setIsSnackOpen(false);
    };

    const handleAddUpdateSnackBar = (action: string) => {
        setSnackMessage(action === "add" ? i18next.t("template.templateSaved") : i18next.t("template.templateUpdated"));
        setSnackBgColor("green");
        setSnackFontColor("white");
        setIsSnackOpen(true);
    };

    const handleDeleteSnackBar = () => {
        setSnackMessage(i18next.t("template.templateDeleted"));
        setSnackBgColor("red");
        setSnackFontColor("white");
        setIsSnackOpen(true);
    };

    const handleDeleteIconClick = (id: string) => {
        setCurrentId(id);
        setDeleteConfirmationDialogVisible(true);
        clickedTemplateId.current = "";
        setShouldRerender(prev => !prev);
    };

    const handleConfirmDelete = () => {
        if (currentId !== null && currentId !== undefined) {
            handleDeleteClick(currentId.toString());
        }
        setDeleteConfirmationDialogVisible(false);
        handleDeleteSnackBar();
        setClickedItem("");
        if (userInfo["userFavourite"][selectedProduct] === currentId) {
            delete userInfo["userFavourite"][selectedProduct];
            makeUserFavouriteTemplate(userInfo["userFavourite"][selectedProduct] ?? "", selectedProduct, true);
        }
        setShouldRerender(prev => !prev);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationDialogVisible(false);
        setCurrentId(undefined);
    };

    const handleCancelConfirmation = () => {
        setConfirmationDialogVisible(false);
    };

    const handleEditClick = async (selectedString: string) => {
        setIsEdit(true);
        const { product, index } = splitData(selectedString);
        setEditingTemplate(product);
        setEditingIndex(parseInt(index));
        const selectedTemplateFields = templateContents[product][index].template[0].fields || {};
        setFields(selectedTemplateFields);
        setUserPromptValues(templateContents[product][index].template);
    };

    const handleCopyAndCreateNewClick = async (selectedString: string) => {
        const response = await loadTemplate(templateProduct);
        const data = await response.json();
        setTemplateContents(data);
        setIsCopyAndCreateNew(true);
        const { product, index } = splitData(selectedString);
        setEditingIndex(parseInt(index));
        setEditingTemplate(product);
        const selectedTemplateFields = templateContents[product][index].template[0].fields || {};
        setFields(selectedTemplateFields);
        let updatedTemplate = [...templateContents[product][index].template];

        if (!isAdmin) {
            updatedTemplate = updatedTemplate.map(template => ({
                ...template,
                isSystemTemplate: false
            }));
        }
        setUserPromptValues(updatedTemplate);
    };
    const handleSaveClick = async () => {
        if (isEdit) {
            if (userPromptValues[0].isDefault) {
                await makeFavouriteTemplate(clickedTemplateProduct.current, "", "change").then(response => {
                    return response.json();
                });
            }
            await updateTemplate(
                templateContents[editingTemplate!][editingIndex!].id,
                templateContents[editingTemplate!][editingIndex!].pk,
                userPromptValues,
                templateProduct,
                0
            ).then(response => {
                handleAddUpdateSnackBar("update");
                return response.json();
            });
        } else {
            if (isAdmin && userPromptValues[0].isDefault && clickedTemplateProduct.current !== "ALL" && clickedTemplateProduct.current === selectedProduct) {
                await makeFavouriteTemplate(clickedTemplateProduct.current, "", "change").then(response => {
                    return response.json();
                });
            }
            if (userPromptValues[0].isDefault && clickedTemplateProduct.current === "ALL" && clickedTemplateProduct.current === selectedProduct) {
                await makeFavouriteTemplate(clickedTemplateProduct.current, "", "change").then(response => {
                    return response.json();
                });
            }
            if (userPromptValues[0].isDefault && clickedTemplateProduct.current !== "ALL" && clickedTemplateProduct.current !== selectedProduct) {
                if (templateProduct !== selectedProduct || templateProduct === "ALL") {
                    await makeFavouriteTemplate(templateProduct, "", "change").then(response => {
                        return response.json();
                    });
                } else {
                    await makeFavouriteTemplate(clickedTemplateProduct.current, "", "change").then(response => {
                        return response.json();
                    });
                }
            }
            if (!isAdmin) {
                userPromptValues[0].isDefault = false;
                await saveTemplate(userPromptValues, templateProduct).then(response => {
                    return response.json();
                });
            } else {
                await saveTemplate(userPromptValues, templateProduct).then(response => {
                    return response.json();
                });
            }
            handleAddUpdateSnackBar("add");

            setTemplateContents((prevContents: any) => {
                const categoryToUpdate = selectedProduct;

                const existingTemplates = Array.isArray(prevContents[categoryToUpdate]) ? prevContents[categoryToUpdate] : [];

                return {
                    ...prevContents,
                    [categoryToUpdate]: [
                        ...existingTemplates,
                        {
                            id: (existingTemplates.length + 1).toString(),
                            template: userPromptValues,
                            pk: "",
                            useCount: 0,
                            product: templateProduct
                        }
                    ]
                };
            });

            setUserPromptValues([
                {
                    title: "",
                    prompt: "",
                    fields: {},
                    previewPrompt: "",
                    isSystemTemplate: false,
                    isDefault: false
                }
            ]);
        }
        isAdd ? setIsAdd(false) : setIsEdit(false);
        setShowAlert(false);
        setShouldRerender(prev => !prev);
    };

    const handleDeleteClick = async (id: string) => {
        await deleteTemplate(id);
        setShouldRerender(prev => !prev);
    };

    const addTemplate = () => {
        setIsAdd(true);
        setFields("");
        const updatedUserPromptValues = [...userPromptValues];
        updatedUserPromptValues[0] = {
            ...updatedUserPromptValues[0],
            title: "",
            prompt: "",
            fields: {},
            previewPrompt: "",
            isSystemTemplate: false,
            isDefault: false
        };
        setUserPromptValues(updatedUserPromptValues);

        setShouldRerender(prev => !prev);
    };

    const sendTemplateContents = (contents: any, tempId: string) => {
        const updatedContents = { ...contents, useCount: contents.useCount + 1 };
        if (updatedContents.pk !== "default@ai-works") {
            updateTemplate(updatedContents.id, updatedContents.pk, updatedContents.template, updatedContents.product, updatedContents.useCount);
        }
        handleTemplateContents(updatedContents.template[0], tempId);
        setShouldRerender(prev => !prev);
    };

    const handleChange = (e: { target: { value: string } }) => {
        const newValue = e.target.value;
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0] = { ...updatedValues[0], prompt: newValue };
            return updatedValues;
        });
        parseText(newValue);
        updateParsedText("", "", newValue);
    };

    const handleFieldChange = (e: any, key: any) => {
        const value = e.target.value;
        // const trimmedValue = value.trim();
        setFields({
            ...fields,
            [key]: value
        });
        updateParsedText(key, value, userPromptValues[0].prompt);
    };

    const parseText = (inputText: any) => {
        const regex = /\{([^}]+)\}/g;
        const matches = inputText.match(regex) || [];
        const newFields: any = { ...fields };
        matches.forEach((match: string) => {
            const key = match.replace(/[{}]/g, "");
            if (key && !newFields[key]) {
                newFields[key] = "";
            }
        });
        setFields(newFields);
    };

    const updateParsedText = (key: string, value: string, promptText: string) => {
        let updatedText = promptText || userPromptValues[0].prompt;
        Object.keys(fields).forEach(fieldKey => {
            let fieldValue = fieldKey === key ? value : fields[fieldKey];
            fieldValue = fieldValue === "" ? `{${fieldKey}}` : fieldValue;
            const regex = new RegExp(`\\{${fieldKey}\\}`, "g");
            updatedText = updatedText.replace(regex, fieldValue);
        });

        if (promptText === "") {
            updatedText = "";
        }

        const object: any = {};
        if (key) {
            object[key] = value;
        }
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0] = {
                ...updatedValues[0],
                fields: { ...fields, ...object },
                previewPrompt: updatedText
            };
            return updatedValues;
        });
    };

    function RenderPreviewText() {
        const promptValues: any = userPromptValues[0].prompt;
        const result = promptValues.replace(/{([^{}]+)}/g, (match: string, p1: string) => {
            if (fields[p1]) {
                return `<span style="font-weight: bold; color: red;">${fields[p1]}</span>`;
            }
            if (fields[p1] === "") {
                return `{${p1}}`;
            }
            return match;
        });
        return <div dangerouslySetInnerHTML={{ __html: result }} />;
    }

    function RenderInputBox() {
        const inputKeys: Array<string> = [];
        const promptValues: any = userPromptValues[0].prompt;
        const regex = /{([^{}]+)}/g;
        let match: RegExpExecArray | null;
        while ((match = regex.exec(promptValues)) !== null) {
            if (match[1]) {
                inputKeys.push(match[1]);
            }
        }
        return inputKeys;
    }

    const renderedInputBoxKeys = RenderInputBox();

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        setUserPromptValues(prevValues => {
            const updatedValues = prevValues.map((value, index) => {
                if (index === 0) {
                    return {
                        ...value,
                        title: newTitle
                    };
                }
                return value;
            });
            return updatedValues;
        });
    };

    const popupStyles = mergeStyleSets({
        root: {
            backgroundcolor: "rgba(0, 0, 0, 0.5)",
            color: "black",
            bottom: "0",
            left: "0",
            position: "absolute",
            right: "0",
            top: "0"
        },
        content: {
            background: "white",
            left: "50%",
            maxWidth: window.innerWidth < 1000 ? "300px" : "1000px",
            padding: "1em 3em 2em 2em",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            border: "2px solid",
            borderColor: "grey"
        }
    });

    function deepEqual(obj1: any, obj2: any) {
        if (obj1 === obj2) {
            return true;
        }
        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const checkContentsEntered = () => {
        if (isAdd && userPromptValues[0].title === "" && userPromptValues[0].prompt === "" && selectedProduct === templateProduct) {
            setIsAdd(false);
        } else {
            if (isAdd) {
                setConfirmationDialogVisible(true);
            }
            if (isEdit || isCopyAndCreateNew) {
                if (
                    userPromptValues[0].title.trim() === templateContents[editingTemplate][editingIndex].template[0].title.trim() &&
                    userPromptValues[0].prompt.trim() === templateContents[editingTemplate][editingIndex].template[0].prompt.trim() &&
                    (!isAdmin ||
                        (userPromptValues[0].isSystemTemplate === templateContents[editingTemplate][editingIndex].template[0].isSystemTemplate &&
                            userPromptValues[0].isDefault === templateContents[editingTemplate][editingIndex].template[0].isDefault)) &&
                    deepEqual(userPromptValues[0].fields, templateContents[editingTemplate][editingIndex].template[0].fields) &&
                    selectedProduct === templateProduct
                ) {
                    setIsEdit(false);
                    setIsCopyAndCreateNew(false);
                } else {
                    setConfirmationDialogVisible(true);
                }
            }
        }
    };

    const handleChangeTemplateProduct = (event: SelectChangeEvent) => {
        setTemplateProduct(event.target.value as string);
    };

    const calculateFontsizeForTemplateProduct = () => {
        if (window.innerWidth < 1000) {
            return "10px";
        }
        if (window.innerWidth < 1150) {
            return "11px";
        }
        if (window.innerWidth < 1300) {
            return "13px";
        }
        if (window.innerWidth < 1700) {
            return "14px";
        } else {
            return "15px";
        }
    };

    const calculateMarginRightForTemplateProduct = () => {
        if (window.innerWidth <= 1050) {
            return "24vw";
        }
        if (window.innerWidth <= 1400) {
            return "20vw";
        } else {
            return "18vw";
        }
    };

    const calculateMarginLeftForTemplateProduct = () => {
        if (window.innerWidth <= 1050) {
            return "17vw";
        }
        if (window.innerWidth <= 1400) {
            return "21vw";
        } else {
            return "21vw";
        }
    };

    const handleCheckSystemTemplate = (event: any) => {
        if (!event.target.checked) {
            setUserPromptValues(prevValues => {
                const updatedValues = [...prevValues];
                updatedValues[0] = {
                    ...updatedValues[0],
                    isDefault: false
                };
                return updatedValues;
            });
        }
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0] = {
                ...updatedValues[0],
                isSystemTemplate: event.target.checked
            };
            return updatedValues;
        });
    };

    const handleCheckFaviTemplate = (event: any) => {
        setShowAlert(prev => !prev);
        if (event.currentTarget.checked) {
            dispatch(setDefaultTemplateIdInStore(clickedTemplateId.current));
        }
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0] = {
                ...updatedValues[0],
                isDefault: event.target.checked
            };
            return updatedValues;
        });
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11
        }
    }));

    const splitData = (selectedString: string) => {
        const array = selectedString?.split("$");
        return { product: array[0], index: array[1] };
    };

    const handleLikeClick = (product: string, templateId: any) => {
        if (
            (userInfo["userFavourite"][selectedProduct] === "" || userInfo["userFavourite"].hasOwnProperty(selectedProduct) === false) &&
            product !== selectedProduct
        ) {
            let allOptionDefaultContent = templateContents["ALL"].filter((temp: any) => temp.template[0].isDefault === true);
            if (templateId === allOptionDefaultContent[0].id) {
                userInfo["userFavourite"][selectedProduct] = userInfo["userFavourite"][selectedProduct] === templateId ? "None" : templateId;
                makeUserFavouriteTemplate(userInfo["userFavourite"][selectedProduct] ?? "None", selectedProduct, false);
                dispatch(setUserDefaultContentInStore(""));
            }
        }
        if (product !== selectedProduct) {
            let allOptionDefaultContent = templateContents["ALL"].filter((temp: any) => temp.template[0].isDefault === true);
            if (templateId === allOptionDefaultContent[0].id) {
                userInfo["userFavourite"][selectedProduct] =
                    userInfo["userFavourite"][selectedProduct] === templateId
                        ? "None"
                        : userInfo["userFavourite"][selectedProduct] === "None"
                          ? templateId
                          : templateId;
                makeUserFavouriteTemplate(userInfo["userFavourite"][selectedProduct] ?? "None", selectedProduct, false);
                dispatch(setUserDefaultContentInStore(""));
            } else {
                userInfo["userFavourite"][selectedProduct] =
                    userInfo["userFavourite"][selectedProduct] === templateId ? allOptionDefaultContent[0].id : templateId;
                makeUserFavouriteTemplate(userInfo["userFavourite"][selectedProduct] ?? allOptionDefaultContent[0].id, selectedProduct, false);
                dispatch(setUserDefaultContentInStore(""));
            }
        }
        if (templateId === null && product === selectedProduct) {
            userInfo["userFavourite"][product] = userInfo["userFavourite"][product] === templateId ? "None" : templateId;
            makeUserFavouriteTemplate(userInfo["userFavourite"][product] ?? "None", product, false);
            dispatch(setUserDefaultContentInStore(""));
        }
        if (templateId !== null && product === selectedProduct) {
            userInfo["userFavourite"][product] = userInfo["userFavourite"][product] === templateId ? "" : templateId;
            makeUserFavouriteTemplate(userInfo["userFavourite"][product] ?? "", product, false);
        }
    };

    const calculateFontsizeForFabIcons = () => {
        if (window.outerWidth < 1160) {
            return "12px";
        }
        if (window.outerWidth < 1290) {
            return "16px";
        }
        if (window.outerWidth < 1370) {
            return "15px";
        }
        if (window.outerWidth < 1450) {
            return "18px";
        }
        if (window.outerWidth < 1700) {
            return "20px";
        } else {
            return "20px";
        }
    };

    const Element = isAdmin && selectedProduct === "ALL" ? Accordion : "div";
    const ElementSummary = isAdmin && selectedProduct === "ALL" ? AccordionSummary : "div";
    const ElementDetails = isAdmin && selectedProduct === "ALL" ? AccordionDetails : "div";

    const handlePanelChange = (panel: any) => {
        setExpandedPanel(prevPanel => (prevPanel === panel ? null : panel)); // Toggle panel
    };

    return (
        <React.Fragment>
            <Dialog
                open={hideDynamicTemplatePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: window.outerWidth < 1050 ? "70%" : "77%",
                        minHeight: "40vh"
                    }
                }}
                disableEscapeKeyDown
            >
                <DialogTitle>
                    {!isAdd && !isEdit && !isCopyAndCreateNew && (
                        <>
                            <Icon iconName="Message" style={{ padding: "5px", fontSize: "20px" }} />
                            <span className={styles.headerFontSize}>{i18next.t("template.templateTitle")} </span>
                            {isAdmin && !loading && systemDefaultContent.length === 0 && (
                                <Tooltip title={i18next.t("template.noDefaultForSystemTemplate")}>
                                    <Chip
                                        icon={<Warning />}
                                        label={i18next.t("template.noDefaultForSystemTemplate")}
                                        variant="filled"
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor: "#e8f0b4",
                                            color: "#000",
                                            marginLeft: window.outerWidth < 1100 ? "10%" : window.outerWidth < 1600 ? "15%" : "20%",
                                            marginRight: window.outerWidth < 1100 ? "" : "10%",
                                            fontSize: window.outerWidth < 1100 ? "10px" : "15px",
                                            maxWidth:
                                                window.outerWidth < 1000 ? "20%" : window.outerWidth < 1200 ? "25%" : window.outerWidth < 1600 ? "30%" : "35%"
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <span style={{ float: "right" }}>
                                {!(isAdd || isEdit || isCopyAndCreateNew) && (
                                    <>
                                        <span>
                                            <TooltipHost
                                                content={
                                                    <table style={{ padding: "10px", width: "100%", borderCollapse: "collapse" }}>
                                                        <tr style={{ backgroundColor: "#f0efed", color: "rgb(25, 195, 125)", padding: "10px" }}>
                                                            <td style={{ padding: "8px" }}>
                                                                {i18next.t("template.systemTemplate")}{" "}
                                                                <span style={{ color: "red" }}>({i18next.t("template.default")})</span>
                                                            </td>
                                                            <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.systemDefaultInfo")}</td>
                                                        </tr>

                                                        <tr style={{ color: "rgb(25, 195, 125)" }}>
                                                            <td style={{ padding: "8px" }}>{i18next.t("template.systemTemplate")}</td>
                                                            <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.systemTemplateInfo")}</td>
                                                        </tr>

                                                        <tr style={{ backgroundColor: "#f0efed", color: "rgb(45, 63, 235)" }}>
                                                            <td style={{ padding: "8px" }}>{i18next.t("template.userTemplate")}</td>
                                                            <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.userTemplateInfo")}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ padding: "8px" }}>
                                                                <StarIcon style={{ color: "red" }} />
                                                            </td>
                                                            <td style={{ padding: "8px", color: "black" }}> {i18next.t("template.defaultTemplateInfo")}</td>
                                                        </tr>

                                                        <tr style={{ backgroundColor: "#f0efed" }}>
                                                            <td style={{ padding: "8px" }}>
                                                                <CheckCircle color="success" />
                                                            </td>
                                                            <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.applyInfo")}</td>
                                                        </tr>
                                                    </table>
                                                }
                                                directionalHint={DirectionalHint.bottomRightEdge}
                                            >
                                                <IconButton
                                                    style={{ color: "blueviolet" }}
                                                    title={i18next.t("template.info")}
                                                    iconProps={{
                                                        iconName: "Info",
                                                        style: {
                                                            fontWeight: "bold",
                                                            fontSize: window.outerWidth < 1050 ? "10px" : "16px"
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        setShowInfo(!showInfo);
                                                    }}
                                                />
                                            </TooltipHost>
                                            <IconButton
                                                style={{ color: "blueviolet" }}
                                                title={i18next.t("template.edit")}
                                                disabled={
                                                    clickedItem === "" ||
                                                    (isAdmin && clickedTemplateProduct.current !== selectedProduct) ||
                                                    (!isAdmin &&
                                                        (clickedTemplateProduct.current !== selectedProduct ||
                                                            clickedTemplateEmailId.current === "default@ai-works"))
                                                }
                                                iconProps={{
                                                    iconName: "Edit",
                                                    style: {
                                                        fontWeight: "bold",
                                                        fontSize: window.outerWidth < 1050 ? "10px" : "16px"
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleEditClick(`${clickedTemplateTitle.current}$${clickedTemplateIndex.current}`);
                                                    setTemplateProduct(clickedTemplateTitle.current);
                                                }}
                                            />
                                            <IconButton
                                                style={{ color: "blueviolet" }}
                                                title={i18next.t("template.copy")}
                                                disabled={clickedItem === ""}
                                                iconProps={{
                                                    iconName: "Copy",
                                                    style: {
                                                        fontWeight: "bold",
                                                        fontSize: window.outerWidth < 1050 ? "10px" : "16px"
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleCopyAndCreateNewClick(`${clickedTemplateTitle.current}$${clickedTemplateIndex.current}`);
                                                    setTemplateProduct(selectedProduct);
                                                }}
                                            />
                                            <IconButton
                                                style={{ color: "blueviolet" }}
                                                title={i18next.t("template.delete")}
                                                disabled={
                                                    clickedItem === "" ||
                                                    (isAdmin && clickedTemplateProduct.current !== selectedProduct) ||
                                                    (!isAdmin &&
                                                        (clickedTemplateProduct.current !== selectedProduct ||
                                                            clickedTemplateEmailId.current === "default@ai-works"))
                                                }
                                                iconProps={{
                                                    iconName: "Delete",
                                                    style: {
                                                        fontWeight: "bold",
                                                        fontSize: window.outerWidth < 1050 ? "10px" : "16px"
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleDeleteIconClick(clickedTemplateId.current);
                                                }}
                                            />
                                        </span>

                                        <IconButton
                                            autoFocus
                                            title={i18next.t("template.newTemplate")}
                                            iconProps={{ iconName: "Add", style: { fontSize: window.outerWidth < 1050 ? "10px" : "16px" } }}
                                            style={{ color: "blueviolet" }}
                                            onClick={() => addTemplate()}
                                        />
                                    </>
                                )}

                                <IconButton
                                    style={{ color: "blueviolet" }}
                                    title={i18next.t("template.close")}
                                    iconProps={{ iconName: "Cancel", style: { fontSize: window.outerWidth < 1050 ? "10px" : "16px" } }}
                                    onClick={() => {
                                        if (isAdd || isEdit || isCopyAndCreateNew) {
                                            setIsAdd(false);
                                            setIsEdit(false);
                                            setIsCopyAndCreateNew(false);
                                        } else {
                                            handleTemplateIconClick();
                                            setClickedItem("");
                                        }
                                    }}
                                />
                            </span>
                        </>
                    )}
                    <Snackbar
                        open={isSnackOpen}
                        autoHideDuration={1000}
                        onClose={handleSnackBarClose}
                        message={snackMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        ContentProps={{
                            style: { backgroundColor: snackBgColor, color: snackFontColor }
                        }}
                    />
                </DialogTitle>

                <DialogContent
                    className={isAdd || isEdit || isCopyAndCreateNew ? styles.TemplateContentContainerInAddEditMode : styles.TemplateContentContainer}
                >
                    {isEdit || isAdd || isCopyAndCreateNew ? (
                        <div className={styles.templateContentInput}>
                            <div style={{ display: "flex" }}>
                                <div className={styles.inputDivs} style={{ width: "80%" }}>
                                    <h5 style={{ alignSelf: "center", width: window.innerWidth < 1500 ? "10%" : "12%" }}>{i18next.t("template.title")}</h5>
                                    <input
                                        value={userPromptValues[0].title}
                                        onChange={handleTitleChange}
                                        placeholder={i18next.t("template.titlePlaceHolder")}
                                        className={styles.templateTitle}
                                        autoFocus
                                    />
                                </div>
                                <div style={{ width: "20%", marginLeft: "5px" }} className={styles.inputDivs}>
                                    <div style={{ backgroundColor: "white", width: "100%" }}>
                                        <FormControl fullWidth style={{ top: "8%" }}>
                                            <InputLabel id="product-select-label">{i18next.t("template.product")}</InputLabel>
                                            <Select
                                                labelId="product-select-label"
                                                id="product-select"
                                                label="Product"
                                                value={templateProduct}
                                                onChange={handleChangeTemplateProduct}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                            right: calculateMarginRightForTemplateProduct(),
                                                            left: calculateMarginLeftForTemplateProduct(),
                                                            fontSize: calculateFontsizeForTemplateProduct()
                                                        }
                                                    }
                                                }}
                                                renderValue={selected => (
                                                    <LightTooltip title={selected} arrow>
                                                        <span>{selected}</span>
                                                    </LightTooltip>
                                                )}
                                            >
                                                {products.map(product => (
                                                    <MenuItem
                                                        key={product.manual_key}
                                                        value={product.text}
                                                        style={{ fontSize: calculateFontsizeForTemplateProduct() }}
                                                    >
                                                        <LightTooltip title={product.text} placement="right-end" followCursor>
                                                            <span>{product.text}</span>
                                                        </LightTooltip>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputDivs}>
                                <h5 style={{ alignSelf: "center", width: "10%", marginRight: "10px" }}>{i18next.t("template.prompt")}</h5>
                                <textarea
                                    draggable={false}
                                    value={userPromptValues[0].prompt}
                                    onChange={handleChange}
                                    placeholder={i18next.t("template.promptPlaceHolder")}
                                    rows={10}
                                    cols={150}
                                    className={styles.templatePromptInput}
                                />
                            </div>
                            {renderedInputBoxKeys.length > 0 && (
                                <div className={styles.inputDivs}>
                                    <h5 style={{ paddingRight: "10px", alignSelf: "center", width: "12%" }}>{i18next.t("template.value")}</h5>
                                    <div className={styles.promptValues}>
                                        {renderedInputBoxKeys.map((key, index) => (
                                            <div className="fields" key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <label style={{ marginLeft: "20px", marginRight: "10px", alignSelf: "center" }}>{key}:</label>
                                                <input
                                                    type="text"
                                                    placeholder={`${key}...`}
                                                    value={fields[key] || ""}
                                                    onChange={e => handleFieldChange(e, key)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className={styles.previewDiv}>
                                <h5 style={{ paddingRight: "10px", alignSelf: "center", width: "12%" }}>{i18next.t("template.preview")}</h5>
                                <div className={styles.previewContent}>
                                    <RenderPreviewText />
                                </div>
                            </div>
                            {showAlert && (
                                <Alert severity="warning">
                                    {userPromptValues[0].isDefault ? i18next.t("template.warningByCheck") : i18next.t("template.warningByUnCheck")}
                                </Alert>
                            )}
                            {isAdmin && (
                                <div className={styles.previewDiv} style={{ justifyContent: "space-between" }}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleCheckSystemTemplate} checked={userPromptValues[0].isSystemTemplate} />}
                                        label={i18next.t("template.systemTemplateText")}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!userPromptValues[0].isSystemTemplate}
                                                onChange={handleCheckFaviTemplate}
                                                checked={userPromptValues[0].isDefault}
                                            />
                                        }
                                        label={i18next.t("template.defaultTemplateText")}
                                    />
                                </div>
                            )}
                            {ConfirmationDialogVisible && (
                                <Layer>
                                    <Popup role="dialog" aria-modal="true" onDismiss={handleCancelConfirmation} className={popupStyles.root}>
                                        <Overlay />
                                        <FocusTrapZone>
                                            <div role="document" className={popupStyles.content}>
                                                <div>
                                                    <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                                    <IconButton
                                                        style={{ color: "green", float: "right" }}
                                                        iconProps={{ iconName: "Cancel" }}
                                                        onClick={handleCancelConfirmation}
                                                    />
                                                    <span
                                                        style={{
                                                            padding: "5px",
                                                            fontSize: window.innerWidth < 1000 ? "12px" : "15px",
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {i18next.t("template.confirmationTitle")}
                                                    </span>
                                                </div>
                                                <span style={{ marginTop: "25px", fontSize: window.innerWidth < 1000 ? "12px" : "15px" }}>
                                                    <h4> {i18next.t("template.confirmationContent")} </h4>
                                                </span>
                                                <div style={{ display: "flex", paddingTop: "25px", justifyContent: "flex-end" }}>
                                                    <PrimaryButton
                                                        onClick={() => {
                                                            setConfirmationDialogVisible(false);
                                                            setTemplateProduct(selectedProduct);
                                                            setIsEdit(false);
                                                            setIsAdd(false);
                                                            setIsCopyAndCreateNew(false);
                                                            setEditingIndex(-1);
                                                        }}
                                                        text={i18next.t("template.confirm")}
                                                    />
                                                    <DefaultButton
                                                        onClick={() => setConfirmationDialogVisible(false)}
                                                        text={i18next.t("template.cancel")}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>
                                            </div>
                                        </FocusTrapZone>
                                    </Popup>
                                </Layer>
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                maxHeight: "70vh",
                                rowGap: "5vh",
                                columnGap: "5vh"
                            }}
                        >
                            {loading ? (
                                <div
                                    style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center", width: "100%", flexDirection: "column" }}
                                >
                                    <div>
                                        <Skeleton variant="text" sx={{ fontSize: "2rem", backgroundColor: "#f2f3f5" }} animation="wave" />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "20px",
                                            justifyContent: "center",
                                            width: "100%",
                                            flexDirection: "row"
                                        }}
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            width={310}
                                            height={318}
                                            className={styles.skeletonTemplateContainer}
                                            animation="wave"
                                            style={{ backgroundColor: "#f2f3f5" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={310}
                                            height={318}
                                            className={styles.skeletonTemplateContainer}
                                            animation="wave"
                                            style={{ backgroundColor: "#f2f3f5" }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={310}
                                            height={318}
                                            className={styles.skeletonTemplateContainer}
                                            animation="wave"
                                            style={{ backgroundColor: "#f2f3f5" }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                selectedProduct === templateProduct && (
                                    <div style={{ width: "100%" }}>
                                        <div>
                                            {showInfo && (
                                                <table className={styles.promptContent} style={{ padding: "10px", width: "100%", borderCollapse: "collapse" }}>
                                                    <tr style={{ backgroundColor: "#f0efed", color: "rgb(25, 195, 125)", padding: "10px" }}>
                                                        <td style={{ padding: "8px", textAlign: "center", verticalAlign: "middle" }} rowSpan={6}>
                                                            {i18next.t("template.info")}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ backgroundColor: "#f0efed", color: "rgb(25, 195, 125)", padding: "10px" }}>
                                                        <td style={{ padding: "8px" }}>
                                                            {i18next.t("template.systemTemplate")}{" "}
                                                            <span style={{ color: "red" }}>({i18next.t("template.default")})</span>
                                                        </td>
                                                        <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.systemDefaultInfo")}</td>
                                                    </tr>

                                                    <tr style={{ color: "rgb(25, 195, 125)" }}>
                                                        <td style={{ padding: "8px" }}>{i18next.t("template.systemTemplate")}</td>
                                                        <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.systemTemplateInfo")}</td>
                                                    </tr>

                                                    <tr style={{ backgroundColor: "#f0efed", color: "rgb(45, 63, 235)" }}>
                                                        <td style={{ padding: "8px" }}>{i18next.t("template.userTemplate")}</td>
                                                        <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.userTemplateInfo")}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ padding: "8px" }}>
                                                            <StarIcon style={{ color: "red" }} />
                                                        </td>
                                                        <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.defaultTemplateInfo")}</td>
                                                    </tr>

                                                    <tr style={{ backgroundColor: "#f0efed" }}>
                                                        <td style={{ padding: "8px" }}>
                                                            <CheckCircle color="success" />
                                                        </td>
                                                        <td style={{ padding: "8px", color: "black" }}>{i18next.t("template.applyInfo")}</td>
                                                    </tr>
                                                </table>
                                            )}
                                        </div>
                                        {Object.keys(templateContents).map(
                                            title =>
                                                title !== "quickSearch" && (
                                                    <Element
                                                        id="each product"
                                                        key={title}
                                                        style={{
                                                            width: "100%",
                                                            padding: "10px",
                                                            border: isAdmin && selectedProduct === "ALL" ? "1px solid black" : "",
                                                            marginBottom: "10px",
                                                            borderRadius: "15px",
                                                            cursor: "pointer"
                                                        }}
                                                        expanded={expandedPanel === title}
                                                        onChange={() => handlePanelChange(title)}
                                                        defaultExpanded={selectedProduct === "ALL"}
                                                    >
                                                        <ElementSummary aria-controls="panel1-content" id={title}>
                                                            {isAdmin && selectedProduct === "ALL" ? (
                                                                <p style={{ textAlign: "center" }}>{title}</p>
                                                            ) : (
                                                                <h3 style={{ textAlign: "center", backgroundColor: "#f5f5f5" }}>{title}</h3>
                                                            )}
                                                        </ElementSummary>
                                                        {(isAdmin && selectedProduct === "ALL" ? expandedPanel === title : true) ? (
                                                            <ElementDetails
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    rowGap: "5vh",
                                                                    columnGap: "5vh",
                                                                    flexWrap: "wrap"
                                                                }}
                                                            >
                                                                {templateContents[title]?.map((e: any, i: number) => {
                                                                    const isUserFavourite =
                                                                        userInfo?.userFavourite?.[title] && userInfo["userFavourite"][title] === e.id;
                                                                    const isDefaultFavourite = e.pk === "default@ai-works" && e.template[0].isDefault === true;

                                                                    const isHoveredTemplate = hoveredTemplate === e.id && title === selectedProduct;

                                                                    const isHoveredTemplateForAdmin = hoveredTemplate === e.id && title === "ALL";
                                                                    const isHoveredTemplateForNonAdmin = hoveredTemplate === e.id;

                                                                    const shouldDisplayFabForAdmin =
                                                                        userInfo?.userFavourite?.hasOwnProperty(title) === false ||
                                                                        userInfo?.userFavourite?.[title] === ""
                                                                            ? isDefaultFavourite || isHoveredTemplate
                                                                            : isUserFavourite || isHoveredTemplate;

                                                                    const shouldDisplayFabForNonAdmin =
                                                                        isHoveredTemplateForNonAdmin ||
                                                                        (userInfo?.userFavourite?.[title] === undefined ||
                                                                        userInfo?.userFavourite?.[title] === ""
                                                                            ? isDefaultFavourite
                                                                            : userInfo.userFavourite[title]
                                                                              ? isUserFavourite
                                                                              : false);

                                                                    const shouldDisplayFabForAdminWithVaryProducts =
                                                                        isHoveredTemplateForAdmin ||
                                                                        (userInfo?.userFavourite?.hasOwnProperty(selectedProduct) === false ||
                                                                        userInfo?.userFavourite?.[selectedProduct] === ""
                                                                            ? isDefaultFavourite
                                                                            : userInfo?.userFavourite?.[selectedProduct] === e.id);

                                                                    const shouldDisplayFabForNonAdminWithVaryProducts =
                                                                        isHoveredTemplateForNonAdmin ||
                                                                        (userInfo?.userFavourite?.hasOwnProperty(selectedProduct) === false ||
                                                                        userInfo?.userFavourite?.[selectedProduct] === ""
                                                                            ? isDefaultFavourite
                                                                            : userInfo?.userFavourite?.[selectedProduct] === e.id);

                                                                    const adminsFab =
                                                                        selectedProduct === "ALL"
                                                                            ? shouldDisplayFabForAdmin
                                                                            : title === selectedProduct
                                                                              ? shouldDisplayFabForAdmin
                                                                              : shouldDisplayFabForAdminWithVaryProducts;

                                                                    const nonAdminsFab =
                                                                        title === selectedProduct
                                                                            ? shouldDisplayFabForNonAdmin
                                                                            : shouldDisplayFabForNonAdminWithVaryProducts;

                                                                    const shouldDisplayFab = isAdmin ? adminsFab : nonAdminsFab;

                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            className={styles.templateZoom}
                                                                            style={{
                                                                                background:
                                                                                    clickedItem === `${title}${i}`
                                                                                        ? e.pk === "default@ai-works"
                                                                                            ? "#19c37d"
                                                                                            : "#d3d3f2"
                                                                                        : ""
                                                                            }}
                                                                            onClick={() => {
                                                                                setClickedItem(`${title}${i}`);
                                                                                clickedTemplateTitle.current = title;
                                                                                clickedTemplateIndex.current = i;
                                                                                clickedTemplateId.current = e.id;
                                                                                clickedTemplateEmailId.current = e.pk;
                                                                                clickedTemplateProduct.current = e.product;
                                                                            }}
                                                                        >
                                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <div
                                                                                    key={i}
                                                                                    className={styles.responseTemplateContainer}
                                                                                    style={{
                                                                                        border:
                                                                                            selectedTemplate === i + 1
                                                                                                ? "4px solid #4396d0"
                                                                                                : "1px solid black",
                                                                                        backgroundColor: selectedTemplate === i + 1 ? "#dce6f7" : "white",
                                                                                        padding: "3%",
                                                                                        resize: "none",
                                                                                        display: "flex",
                                                                                        flexDirection: "column"
                                                                                    }}
                                                                                    onClick={() => handleTemplateClick(i + 1)}
                                                                                    onMouseEnter={() => {
                                                                                        setHoveredTemplate(e.id);
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setHoveredTemplate(null);
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                            height: "13%"
                                                                                        }}
                                                                                    >
                                                                                        <Tooltip title={e.template[0].title}>
                                                                                            <h4
                                                                                                style={{
                                                                                                    color: e.pk === "default@ai-works" ? "#19c37d" : "#2d3feb"
                                                                                                }}
                                                                                                className={styles.promptTitle}
                                                                                            >
                                                                                                {e.template[0].title}
                                                                                            </h4>
                                                                                        </Tooltip>

                                                                                        <span>
                                                                                            {shouldDisplayFab && (
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        (userInfo?.userFavourite?.[title] &&
                                                                                                            userInfo?.userFavourite?.[title] === e.id) ||
                                                                                                        (e.template[0].isDefault &&
                                                                                                            userInfo?.userFavourite?.[title] === "")
                                                                                                            ? i18next.t("template.defaultTemplateTooltip")
                                                                                                            : i18next.t(
                                                                                                                  "template.clickToMakeDefaultTemplateTooltip"
                                                                                                              )
                                                                                                    }
                                                                                                >
                                                                                                    <StarIcon
                                                                                                        aria-label="like"
                                                                                                        onClick={(el: any) => {
                                                                                                            if (
                                                                                                                isAdmin &&
                                                                                                                title !== selectedProduct &&
                                                                                                                selectedProduct === "ALL"
                                                                                                            )
                                                                                                                return;
                                                                                                            el.stopPropagation();
                                                                                                            if (
                                                                                                                e.pk === "default@ai-works" &&
                                                                                                                e.template[0].isDefault &&
                                                                                                                (!userInfo?.userFavourite?.hasOwnProperty(
                                                                                                                    title
                                                                                                                ) ||
                                                                                                                    userInfo?.userFavourite?.[title] === "")
                                                                                                            ) {
                                                                                                                title === selectedProduct
                                                                                                                    ? handleLikeClick(title, null)
                                                                                                                    : handleLikeClick(title, e.id);
                                                                                                            } else {
                                                                                                                if (
                                                                                                                    e.pk === "default@ai-works" &&
                                                                                                                    e.template[0].isDefault &&
                                                                                                                    userInfo?.userFavourite?.[title] === e.id &&
                                                                                                                    !isStarSelected
                                                                                                                ) {
                                                                                                                    handleLikeClick(title, null);
                                                                                                                } else {
                                                                                                                    handleLikeClick(title, e.id);
                                                                                                                }
                                                                                                            }

                                                                                                            setShouldRerender(prev => !prev);
                                                                                                        }}
                                                                                                        style={{
                                                                                                            color:
                                                                                                                (userInfo?.userFavourite?.[
                                                                                                                    title === selectedProduct
                                                                                                                        ? title
                                                                                                                        : selectedProduct
                                                                                                                ] &&
                                                                                                                    userInfo?.userFavourite?.[
                                                                                                                        title === selectedProduct
                                                                                                                            ? title
                                                                                                                            : selectedProduct
                                                                                                                    ] === e.id) ||
                                                                                                                (e.pk === "default@ai-works" &&
                                                                                                                    e.template[0].isDefault &&
                                                                                                                    (userInfo?.userFavourite?.hasOwnProperty(
                                                                                                                        title === selectedProduct
                                                                                                                            ? title
                                                                                                                            : selectedProduct
                                                                                                                    ) === false ||
                                                                                                                        userInfo?.userFavourite?.[
                                                                                                                            title === selectedProduct
                                                                                                                                ? title
                                                                                                                                : selectedProduct
                                                                                                                        ] === "")) ||
                                                                                                                (selectedProduct === "ALL" &&
                                                                                                                    title !== selectedProduct)
                                                                                                                    ? "red"
                                                                                                                    : "grey",
                                                                                                            cursor:
                                                                                                                isAdmin &&
                                                                                                                title !== selectedProduct &&
                                                                                                                selectedProduct === "ALL"
                                                                                                                    ? "not-allowed"
                                                                                                                    : "pointer"
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                    {Array.isArray(e.template) &&
                                                                                        e.template.map((element: any) => (
                                                                                            <div className={styles.promptContent} key={i}>
                                                                                                {element.previewPrompt}
                                                                                            </div>
                                                                                        ))}
                                                                                    <footer
                                                                                        className={styles.promptFooter}
                                                                                        style={{
                                                                                            color: e.pk === "default@ai-works" ? "#19c37d" : "#2d3feb"
                                                                                        }}
                                                                                    >
                                                                                        {hoveredTemplate === e.id && (
                                                                                            <Tooltip title={i18next.t("template.apply")}>
                                                                                                <CheckCircle
                                                                                                    color="success"
                                                                                                    onClick={() => {
                                                                                                        setIsEdited(false);
                                                                                                        if (clickedTemplateIndex !== undefined) {
                                                                                                            sendTemplateContents(
                                                                                                                templateContents[title][i],
                                                                                                                e.id
                                                                                                            );
                                                                                                        }
                                                                                                        snackbar();
                                                                                                        setClickedItem("");
                                                                                                    }}
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        fontSize: calculateFontsizeForFabIcons(),
                                                                                                        float: "left",
                                                                                                        marginTop: "5%"
                                                                                                    }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        )}
                                                                                        <div style={{ marginTop: "5%" }}>
                                                                                            {e.pk === "default@ai-works" && e.template[0].isDefault === true ? (
                                                                                                <>
                                                                                                    {i18next.t("template.systemTemplate")}{" "}
                                                                                                    <span style={{ color: "red" }}>
                                                                                                        ({i18next.t("template.default")})
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : e.pk === "default@ai-works" &&
                                                                                              e.template[0].isDefault === false ? (
                                                                                                i18next.t("template.systemTemplate")
                                                                                            ) : (
                                                                                                i18next.t("template.userTemplate")
                                                                                            )}
                                                                                        </div>
                                                                                    </footer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </ElementDetails>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Element>
                                                )
                                        )}
                                    </div>
                                )
                            )}
                            {deleteConfirmationDialogVisible && (
                                <Layer>
                                    <Popup role="dialog" aria-modal="true" onDismiss={handleCancelDelete} className={popupStyles.root}>
                                        <Overlay />
                                        <FocusTrapZone>
                                            <div role="document" className={popupStyles.content}>
                                                <div>
                                                    <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                                    <IconButton
                                                        style={{ color: "green", float: "right" }}
                                                        iconProps={{ iconName: "Cancel" }}
                                                        onClick={handleCancelDelete}
                                                    />
                                                    <span
                                                        style={{
                                                            padding: "5px",
                                                            fontSize: window.innerWidth < 1000 ? "12px" : "15px",
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {i18next.t("template.confirmDeleteTitle")}
                                                    </span>
                                                </div>
                                                <span style={{ marginTop: "25px", fontSize: window.innerWidth < 1000 ? "12px" : "15px" }}>
                                                    {i18next.t("template.confirmDeleteMessage")}
                                                </span>
                                                <div style={{ display: "flex", paddingTop: "25px", justifyContent: "flex-end" }}>
                                                    <PrimaryButton onClick={handleConfirmDelete} text={i18next.t("template.confirm")} />
                                                    <DefaultButton
                                                        onClick={handleCancelDelete}
                                                        text={i18next.t("template.cancel")}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>
                                            </div>
                                        </FocusTrapZone>
                                    </Popup>
                                </Layer>
                            )}
                        </div>
                    )}

                    {isEdit || isAdd || isCopyAndCreateNew ? (
                        <div className={styles.templateActions}>
                            <>
                                <IconButton
                                    title={i18next.t("template.close")}
                                    style={{ color: "blueviolet", float: "right" }}
                                    iconProps={{ iconName: "Cancel", style: { fontSize: "20px" } }}
                                    onClick={() => {
                                        if (isAdd || isEdit || isCopyAndCreateNew) {
                                            checkContentsEntered();
                                            setShowAlert(false);
                                        } else {
                                            handleTemplateIconClick();
                                            setShowAlert(false);
                                        }
                                    }}
                                />
                                <IconButton
                                    style={{
                                        float: "right",
                                        marginTop: "20px",
                                        color: "blueviolet"
                                    }}
                                    title={i18next.t("template.save")}
                                    iconProps={{ iconName: "Save", style: { fontSize: "20px" } }}
                                    onClick={() => {
                                        setIsAdd(false);
                                        setIsEdit(false);
                                        setIsCopyAndCreateNew(false);
                                        handleSaveClick();
                                        setClickedItem("");
                                    }}
                                    disabled={
                                        (templateContents[editingTemplate] &&
                                            templateContents[editingTemplate][editingIndex] &&
                                            userPromptValues[0].prompt.trim() === templateContents[editingTemplate][editingIndex]?.template[0].prompt.trim() &&
                                            userPromptValues[0].title.trim() === templateContents[editingTemplate][editingIndex]?.template[0].title.trim() &&
                                            selectedProduct === templateProduct &&
                                            (!isAdmin ||
                                                (userPromptValues[0].isSystemTemplate ===
                                                    templateContents[editingTemplate][editingIndex].template[0].isSystemTemplate &&
                                                    userPromptValues[0].isDefault === templateContents[editingTemplate][editingIndex].template[0].isDefault)) &&
                                            deepEqual(userPromptValues[0].fields, templateContents[editingTemplate][editingIndex]?.template[0].fields)) ||
                                        userPromptValues[0].title.trim().length === 0 ||
                                        userPromptValues[0].prompt.trim().length === 0 ||
                                        templateProduct === `${i18next.t("chat.productFilterText")}`
                                    }
                                />
                                <div style={{ color: "blueviolet", float: "right" }}>
                                    <TooltipHost
                                        content={
                                            <>
                                                <p>
                                                    <b>{i18next.t("template.infoTitle")}</b>
                                                </p>
                                                <p>
                                                    {i18next.t("template.infoEg")} {"{ "}
                                                    <span style={{ color: "red" }}>{i18next.t("template.infoSubject")}</span>
                                                    {" }"}
                                                </p>
                                            </>
                                        }
                                        directionalHint={DirectionalHint.bottomRightEdge}
                                    >
                                        <IconButton
                                            style={{ color: "blueviolet", float: "right", marginTop: "20px" }}
                                            title={i18next.t("template.info")}
                                            iconProps={{ iconName: "Info", style: { fontSize: "20px" } }}
                                        />
                                    </TooltipHost>
                                </div>
                            </>
                        </div>
                    ) : null}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default DynamicTemplatePrompt;
