import { useState, useEffect, useRef } from "react";
import { Stack } from "@fluentui/react";
import { Button } from "@fluentui/react-components";
import { BookTemplate20Filled, Send28Filled } from "@fluentui/react-icons";
import i18next from "../../i18n/i18n";
import styles from "./QuestionInput.module.css";
import { MentionsInput, Mention } from "react-mentions";
import MentionStyle from "./MentionStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled, Zoom } from "@mui/material";
import { userInfo } from "../../authConfig";
import { setSaveTemplateIdInStore } from "../../Store/TemplateContents";
interface Props {
    onSend: (question: string, questionContaninsTemplate: boolean) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    onTemplateIconClick?: () => void;
    templateContent?: any;
    selectedTemplateContentId?: string;
    onClearTemplateContent?: () => void;
    disableTextarea: boolean;
    productIndex: { manual_key: string; faq_key: string };
    isAdmin: boolean;
    isEdited: boolean;
    setIsEdited: (isEdited: boolean) => void;
    clearChatTriggered?: boolean | undefined;
    setClearChatTriggered?: (isEdited: boolean) => void;
    chatContentCleared?: boolean | undefined;
    setChatContentCleared?: (chatContentCleared: boolean) => void;
    selectedAppType: string;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    onTemplateIconClick,
    templateContent,
    selectedTemplateContentId,
    onClearTemplateContent,
    disableTextarea,
    productIndex,
    isAdmin,
    isEdited,
    setIsEdited,
    clearChatTriggered,
    setClearChatTriggered,
    chatContentCleared,
    setChatContentCleared,
    selectedAppType
}: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [changingTemplateContent, setChangingTemplateContent] = useState<boolean>(false);

    const templateContents = useSelector((store: RootState) => store.templateContents.templateContents);
    const templateTitles = useSelector((store: RootState) => store.templateContents.templateTitles);
    const userDefaultContent = useSelector((store: RootState) => store.templateContents.userDefaultContent);
    const systemDefaultContent = useSelector((store: RootState) => store.templateContents.systemDefaultContent);
    const [defaultContentState, setDefaultContentState] = useState<string>(userDefaultContent ?? systemDefaultContent ?? "");
    const selectedProduct = useSelector((store: RootState) => store.templateContents.selectedProduct);
    const mentionsInputRef = useRef<HTMLTextAreaElement | null>(null);
    const [questionContainsTemplate, setQuestionContainsTemplate] = useState<boolean>(true);
    const [difference, setDifference] = useState("");

    let title: any = templateContents.find((content: any) => content.product);

    let allOptionsTemplateId = userInfo?.["userFavourite"]?.["ALL"];
    const allOptionsUserDefault: any = templateContents?.filter((content: any) => content.id === allOptionsTemplateId);

    const userFavouriteContent = userInfo["userFavourite"]?.[selectedProduct];

    useEffect(() => {
        if (templateContent && !changingTemplateContent) {
            setDefaultContentState(templateContent.previewPrompt);
            return;
        }
        if (userFavouriteContent === null) {
            setDefaultContentState("");
        } else {
            if (clearChatTriggered || !chatContentCleared) {
                const adminQuestionInput = userInfo["userFavourite"]?.hasOwnProperty(selectedProduct)
                    ? userDefaultContent === "" || userDefaultContent === undefined
                        ? title.product !== selectedProduct
                            ? userInfo["userFavourite"][selectedProduct] !== "" && userInfo["userFavourite"][selectedProduct] !== "None"
                                ? title.template[0]?.previewPrompt
                                : userInfo["userFavourite"][selectedProduct] === "None"
                                  ? ""
                                  : systemDefaultContent
                            : systemDefaultContent
                        : userDefaultContent === ""
                          ? systemDefaultContent
                          : userDefaultContent
                    : userInfo["userFavourite"]?.["ALL"] !== null
                      ? title === selectedProduct
                          ? allOptionsUserDefault[0]?.template[0]?.previewPrompt
                          : systemDefaultContent
                      : "";

                const nonAdminQuestionInput = userInfo["userFavourite"]?.hasOwnProperty(selectedProduct)
                    ? userDefaultContent === "" || userDefaultContent === undefined
                        ? title.product !== selectedProduct
                            ? userInfo["userFavourite"][selectedProduct] !== "" && userInfo["userFavourite"][selectedProduct] !== "None"
                                ? title.template[0]?.previewPrompt
                                : userInfo["userFavourite"][selectedProduct] === "None"
                                  ? ""
                                  : systemDefaultContent
                            : systemDefaultContent
                        : userDefaultContent === ""
                          ? systemDefaultContent
                          : userDefaultContent
                    : userInfo["userFavourite"]?.["ALL"] !== null
                      ? allOptionsUserDefault[0]?.template[0]?.previewPrompt ?? systemDefaultContent
                      : "";
                const defaultContentState = isAdmin ? (chatContentCleared ? "" : adminQuestionInput) : chatContentCleared ? "" : nonAdminQuestionInput;
                setDefaultContentState(defaultContentState);
            }
        }
    }, [userDefaultContent, systemDefaultContent, userFavouriteContent, allOptionsUserDefault]);

    useEffect(() => {
        if (!isEdited) {
            setQuestion(templateContent.previewPrompt ?? "");
            if (templateContent.previewPrompt) {
                setDefaultContentState(templateContent.previewPrompt);
                setQuestion(templateContent.previewPrompt);
            }
            if (!clearChatTriggered) {
                setDefaultContentState("");
                setQuestion("");
            } else {
                return;
            }
        }
    }, [templateContent, isEdited, clearChatTriggered]);

    useEffect(() => {
        if (mentionsInputRef.current) {
            mentionsInputRef.current.focus();
            const length = mentionsInputRef.current.value.length;
            mentionsInputRef.current.setSelectionRange(length, length);
        }
    }, []);

    const formattedTemplateTitles = templateTitles.slice(0, 5).map(title => ({
        id: title,
        display: title
    }));

    const sendQuestion = () => {
        if (questionContainsTemplate) {
            defaultContentState.includes(templateContent.previewPrompt) ? setQuestionContainsTemplate(true) : setQuestionContainsTemplate(false);
        }

        if (disabled || (!question.trim() && !defaultContentState?.trim())) {
            return;
        }

        if (!isAdmin && ((selectedAppType === "Manuals" && productIndex?.manual_key === "") || (selectedAppType === "FAQ" && productIndex?.faq_key === ""))) {
            onSend("invalid_selection", false);
            return;
        }
        let filteredQuestion = question.match(/@\[(.*?)\]/)?.[1];
        onSend(filteredQuestion ? filteredQuestion : question === "" ? defaultContentState : question, questionContainsTemplate);
        setQuestionContainsTemplate(true);
        if (clearOnSend) {
            setQuestion("");
            setIsEdited(false);
            setDefaultContentState("");
            setChangingTemplateContent(false);
            setChatContentCleared?.(true);
            if (mentionsInputRef.current) {
                mentionsInputRef.current.value = "";
            }
            if (setClearChatTriggered) {
                setClearChatTriggered(false);
            }
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (disabled) {
            return;
        }
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
            setIsEdited(false);
            setDefaultContentState("");
            if (onClearTemplateContent) {
                onClearTemplateContent();
            }
        }
    };

    const clearInput = () => {
        setQuestion("");
        setDefaultContentState("");
        setIsEdited(false);
        setChatContentCleared?.(true);
        setSaveTemplateIdInStore("");
        setQuestionContainsTemplate(false);
        if (onClearTemplateContent) {
            onClearTemplateContent();
        }
    };

    function isSubset(oldValue: string, newValue: string) {
        if (typeof oldValue !== "string" || typeof newValue !== "string") {
            throw new Error("Both values must be strings.");
        }
        let index = 0;
        if (oldValue.length === 0 && newValue.length > 0) {
            return false;
        }
        for (const char of oldValue) {
            index = newValue.indexOf(char, index);
            if (index === -1) {
                return false;
            }
            index++;
        }
        setDifference(newValue.slice(oldValue.length));
        return true;
    }

    const onQuestionChange = (newValue?: string) => {
        if (!newValue) {
            clearInput();
        } else if (newValue.length <= 1000) {
            if (templateContent.previewPrompt) {
                setChangingTemplateContent(true);
            }
            if (templateContent.previewPrompt) {
                isSubset(templateContent.previewPrompt, newValue) ? setQuestionContainsTemplate(true) : setQuestionContainsTemplate(false);
            } else {
                isSubset(userDefaultContent, newValue) ? setQuestionContainsTemplate(true) : setQuestionContainsTemplate(false);
            }
            setQuestion(newValue);
            setIsEdited(true);
            setDefaultContentState("");
            setChatContentCleared?.(true);
        }
    };

    const sendQuestionDisabled = disabled || (!question.trim() && !defaultContentState?.trim());

    const handleTemplateIcon = () => {
        setChangingTemplateContent(false);
        if (onTemplateIconClick) {
            onTemplateIconClick();
        }
    };

    const handleMentionSelect = (_id: string | number, display: string) => {
        let previewPrompt: any = templateContents.find((content: any) => content.template[0].title.includes(display));
        setQuestion(previewPrompt.template[0].previewPrompt);
    };

    const renderMentionSuggestion = (entry: any, search: string, highlightedDisplay: React.ReactNode) => {
        const content: any = templateContents.find((template: any) => template.template[0].title === entry.display);
        const previewPrompt = content?.template[0].previewPrompt ?? "No preview available";
        const formattedPreviewPrompt = previewPrompt.replace(/\n/g, "<br />");
        return (
            <LightTooltip
                content={previewPrompt}
                title={<span dangerouslySetInnerHTML={{ __html: formattedPreviewPrompt }} />}
                followCursor
                TransitionComponent={Zoom}
                placement="right-end"
            >
                <div>{highlightedDisplay}</div>
            </LightTooltip>
        );
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11
        }
    }));

    return (
        <div style={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
            <Stack style={{ position: "relative", flexShrink: 0 }}>
                <LightTooltip title={i18next.t("template.templatePromptIcon")} placement="top" TransitionComponent={Zoom}>
                    <Button
                        id="TemplateIcon"
                        size="large"
                        icon={
                            <div className={styles.templateIcon}>
                                <BookTemplate20Filled primaryFill="red" onClick={handleTemplateIcon} />
                            </div>
                        }
                        disabled={disableTextarea || (!isAdmin && (productIndex.manual_key === "0" || productIndex.faq_key === ""))}
                        style={{ marginBottom: "1vh", paddingRight: "1vw" }}
                    />
                </LightTooltip>
            </Stack>
            <Stack horizontal className={styles.questionInputContainer} style={{ background: disableTextarea ? "#F2F2F2" : "#fff" }}>
                <div style={{ width: "97%", height: "100%" }}>
                    <MentionsInput
                        inputRef={mentionsInputRef}
                        id="question-input"
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        value={defaultContentState || (isEdited ? question : templateContent.previewPrompt) || ""}
                        onChange={e => onQuestionChange(e.target.value)}
                        onKeyDown={onEnterPress}
                        disabled={disableTextarea}
                        autoFocus
                        style={MentionStyle}
                        forceSuggestionsAboveCursor={true}
                        allowSuggestionsAboveCursor={true}
                    >
                        <Mention trigger="/" data={formattedTemplateTitles} onAdd={handleMentionSelect} renderSuggestion={renderMentionSuggestion} />
                    </MentionsInput>
                    {isAdmin && (
                        <h6 style={{ float: "right", marginRight: "1%", marginBottom: "1.4%", marginTop: "1%" }}> {i18next.t("template.quickSearch")} </h6>
                    )}
                </div>
                <div className={styles.questionInputButtonsContainer}>
                    <LightTooltip title={i18next.t("questionInput.tooltip")} placement="top">
                        <Button
                            id="sendButton"
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </LightTooltip>
                </div>
            </Stack>
        </div>
    );
};
