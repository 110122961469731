import { DefaultButton, FocusTrapZone, IconButton, Layer, mergeStyleSets, Overlay, Popup, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import i18next from "../../i18n/i18n";

interface Props {
    showWarningFlag: boolean;
    warningMsg: string | undefined;
    onClose: () => void;
}

export const SelectedProductUnAvailableInfoMsgDialog = ({ showWarningFlag, warningMsg, onClose }: Props) => {
    const [showWarning, setShowWarning] = useState<boolean>(false);

    useEffect(() => {
        setShowWarning(showWarningFlag);
    }, []);

    const popupStyles = mergeStyleSets({
        root: {
            background: "rgba(0, 0, 0, 0.2)",
            bottom: "0",
            left: "0",
            position: "fixed",
            right: "0",
            top: "0"
        },
        content: {
            background: "white",
            left: "50%",
            maxWidth: "1000px",
            padding: "0 0em 2em",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
    });
    return (
        <>
            {showWarning && (
                <Layer>
                    <Popup className={popupStyles.root} role="dialog" aria-modal="true">
                        <Overlay />
                        <FocusTrapZone>
                            <div role="document" className={popupStyles.content}>
                                <div>
                                    <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                    <IconButton
                                        style={{ color: "green", float: "right" }}
                                        iconProps={{ iconName: "Cancel" }}
                                        onClick={() => {
                                            setShowWarning(false);
                                            onClose();
                                        }}
                                    />
                                    <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                        <Text>{warningMsg}</Text>
                                    </span>
                                </div>
                                <DefaultButton
                                    style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px", top: "10px" }}
                                    onClick={() => {
                                        setShowWarning(false);
                                        onClose();
                                    }}
                                >
                                    {i18next.t("chat.ok")}
                                </DefaultButton>
                            </div>
                        </FocusTrapZone>
                    </Popup>
                </Layer>
            )}
        </>
    );
};
