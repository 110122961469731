import { createSlice } from "@reduxjs/toolkit";

const TemplateContents = createSlice({
    name: "templateContents",
    initialState: {
        templateContents: [],
        templateTitles: [],
        userDefaultContent: "",
        systemDefaultContent: "",
        defaultTemplateId: "",
        selectedProduct: "",
        saveTemplateId: ""
    },
    reducers: {
        setTemplateContentsInStore: (state, actions) => {
            state.templateContents = actions.payload;
            state.templateTitles = actions.payload.map((template: any) => template.template[0].title);
        },
        setUserDefaultContentInStore: (state, actions) => {
            state.userDefaultContent = actions.payload;
        },
        setSystemDefaultContentInStore: (state, actions) => {
            state.systemDefaultContent = actions.payload;
        },
        setDefaultTemplateIdInStore: (state, actions) => {
            state.defaultTemplateId = actions.payload;
        },
        setSelectedProductInStore: (state, actions) => {
            state.selectedProduct = actions.payload;
        },
        setSaveTemplateIdInStore: (state, actions) => {
            state.saveTemplateId = actions.payload;
        }
    }
});

export default TemplateContents.reducer;
export const {
    setTemplateContentsInStore,
    setUserDefaultContentInStore,
    setDefaultTemplateIdInStore,
    setSystemDefaultContentInStore,
    setSelectedProductInStore,
    setSaveTemplateIdInStore
} = TemplateContents.actions;
