import React, { useCallback, useMemo, useState } from "react";
import { mergeStyleSets, Stack, IconButton, Popup, Layer, Overlay, FocusTrapZone, Checkbox, DefaultButton, Icon, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { getCitationFilePath, FeedbackResponse, saveSharedChatValueApi, ChatAppResponse, AppType, ChatSessionMode, saveUserDetails } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { LockIcon } from "../LockIcon/LockIcon";
import { Toast, ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";
import { appInfo, jwtToken, userInfo } from "../../authConfig";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { SelectedProductUnAvailableInfoMsgDialog } from "./SelectedProductUnAvailableInfoMsgDialog";
import Joyride, { STATUS, Step } from "react-joyride";
import { useSetState } from "react-use";

interface Props {
    primaryId: number;
    response: any;
    isStreaming: boolean;
    isSelected?: boolean;
    onCitationClicked: (filePath: string, citationIndex: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    isHistory?: boolean;
    doctype?: boolean;
    displayLink?: () => void;
    isLastAnswer: boolean;
    isFeedbackPage: boolean;
    onSend: (appType: AppType) => void;
    isLoading?: boolean;
    selectedAppType: string;
    setSelectedAppType: (appType: AppType) => void;
    lastAnswerIndexRefValue: number;
    onTabClicked: () => void;
    currentPageView: ChatSessionMode;
    answerAreaRef?: any;
    chatResponseAreaRef?: any;
    lastQuestionIndexValue?: string;
    activateAnswerTour?: boolean;
    setActivateAnswerTour?: (tourFlag: boolean) => void;
}

interface LikeComponentProps {
    isLikePopupVisible: boolean;
    isHistory: boolean | undefined;
    feedbackText: string | undefined;
    hideLikePopup: () => void;
}

interface DisLikeComponentProps {
    isDisLikePopupVisible: boolean;
    isHistory: boolean | undefined;
    feedbackText: string | undefined;
    hideDisLikePopup: () => void;
    selectedOptions: number | undefined;
}

interface ShareProps {
    isSharChatPopupVisible: boolean;
}

type TokenValues = {
    isInchargeOfAiWorks: string;
    userId: string;
};

interface State {
    run: boolean;
    steps: Step[];
}

const popupStyles = mergeStyleSets({
    root: {
        background: "rgba(0, 0, 0, 0.5)",
        bottom: "0",
        left: "0",
        position: "absolute",
        right: "0",
        top: "0"
    },
    content: {
        background: "white",
        left: "50%",
        maxWidth: "1000px",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        border: "2px solid",
        borderColor: "grey",
        padding: "15px"
    },
    thumbTextBox: {
        padding: "5px",
        width: "700px",
        borderRadius: "10px"
    }
});

const stackTokens = { childrenGap: 10, padding: 25 };
let activeTab = "";
export const AnswersHistory = ({
    primaryId,
    response,
    doctype,
    isStreaming,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    isHistory,
    displayLink,
    isLastAnswer,
    isFeedbackPage,
    onSend,
    isLoading,
    selectedAppType,
    setSelectedAppType,
    lastAnswerIndexRefValue,
    onTabClicked,
    currentPageView,
    answerAreaRef,
    chatResponseAreaRef,
    lastQuestionIndexValue,
    activateAnswerTour,
    setActivateAnswerTour
}: Props) => {
    const answer: ChatAppResponse = response["FAQ"];
    const manualsAnswer: ChatAppResponse = response["Manuals"];
    const faqFollowupQuestions = answer?.choices[0]?.context?.followup_questions;
    const faqDataPoints = answer?.choices[0]?.context?.data_points ?? [];
    const faqMessageContent = typeof answer?.choices[0]?.message?.content === "string" ? answer?.choices[0]?.message?.content : "";
    const faqFileMapping = answer?.choices[0]?.context.file_mapping;
    const faqParsedAnswer = useMemo(() => parseAnswerToHtml(faqMessageContent, isStreaming, onCitationClicked, faqDataPoints, faqFileMapping), [answer]);
    const faqSanitizedAnswerHtml = DOMPurify.sanitize(faqParsedAnswer.answerHtml);

    const manualFollowupQuestions = manualsAnswer?.choices[0]?.context?.followup_questions;
    const manualDataPoints = manualsAnswer?.choices[0]?.context?.data_points ?? [];
    const manualMessageContent = typeof manualsAnswer?.choices[0]?.message?.content === "string" ? manualsAnswer?.choices[0]?.message?.content : "";
    const manualsFileMapping = manualsAnswer?.choices[0]?.context.file_mapping;
    const manualParsedAnswer = useMemo(
        () => parseAnswerToHtml(manualMessageContent, isStreaming, onCitationClicked, manualDataPoints, manualsFileMapping),
        [manualsAnswer]
    );
    const manualSanitizedAnswerHtml = DOMPurify.sanitize(manualParsedAnswer.answerHtml);
    const faqFeedbacks: FeedbackResponse = answer?.choices[0].context.feedback ?? { status: false, data: { text: "", type: 0, time: "" } };
    const manualFeedbacks: FeedbackResponse = manualsAnswer?.choices[0].context.feedback ?? { status: false, data: { text: "", type: 0, time: "" } };

    const [isLikePopupVisible, { setTrue: showLikePopup, setFalse: hideLikePopup }] = useBoolean(false);
    const [isDisLikePopupVisible, { setTrue: showDisLikePopup, setFalse: hideDisLikePopup }] = useBoolean(false);
    const [isSharChatPopupVisible, { setTrue: showSharChatPopup, setFalse: hideShareChatPopup }] = useBoolean(false);
    const [tabIndex, setTabIndex] = useState(selectedAppType + primaryId);
    const selectedProduct = (selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.product_filter;
    const tokenValues = jwtDecode(jwtToken || "") as TokenValues;
    const isAdmin = tokenValues["isInchargeOfAiWorks"] === "True";
    const [selectedProductUnavailbleInfoDlgOpen, setSelectedProductUnavailbleInfoDlgOpen] = useState<boolean>(false);

    const [tourOne, setTourOne] = useSetState<State>({
        run: false,
        steps: [
            {
                content: i18next.t(`appTour.answerTour.shareIcon`),
                target: `#ShareIcon${lastQuestionIndexValue}`,
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                }
            },
            {
                content: i18next.t(`appTour.answerTour.likeIcon`),
                target: `#LikeIcon${lastQuestionIndexValue}`
            },
            {
                content: i18next.t(`appTour.answerTour.disLikeIcon`),
                target: `#DisLikeIcon${lastQuestionIndexValue}`
            }
        ]
    });

    const [tourTwo, setTourTwo] = useSetState<State>({
        run: false,
        steps: [
            {
                content: i18next.t(`appTour.answerTour.faqTabInfo`),
                target: `[data-tour-id="FAQTab${lastQuestionIndexValue}"]`,
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                },
                styles: {
                    tooltip: {
                        width: "200px",
                        maxWidth: "200px"
                    }
                }
            },
            {
                content: i18next.t(`appTour.answerTour.manualsTabInfo`),
                target: `[data-tour-id="ManualsTab${lastQuestionIndexValue}"]`
            }
        ]
    });

    useEffect(() => {
        setTabIndex(selectedAppType + primaryId);
    }, [selectedAppType]);

    const endTourOne = useCallback(() => {
        setTourOne(prevState => ({
            ...prevState,
            run: false
        }));
        if (userInfo.answerTourDone === "false") {
            saveUserSettings({ chatTourDone: "true", templateTourDone: "true", answerTourDone: "true", answerTabTourDone: "false" });
        }
        userInfo.answerTourDone = "true";
        setTimeout(() => {
            setTourTwo(prevState => ({
                ...prevState,
                run: true
            }));
        }, 1500);
    }, []);

    const endTourTwo = useCallback(() => {
        setTourTwo(prevState => ({
            ...prevState,
            run: false
        }));
        if (userInfo.answerTabTourDone === "false") {
            saveUserSettings({ chatTourDone: "true", templateTourDone: "true", answerTourDone: "true", answerTabTourDone: "true" });
        }
        userInfo.answerTabTourDone = "true";
    }, []);

    const saveUserSettings = async (settings: object) => {
        try {
            await saveUserDetails(settings)
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    const handleJoyrideCallback = (data: any, isTabTour: boolean) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            if (isTabTour) {
                endTourTwo();
                if (setActivateAnswerTour) {
                    setActivateAnswerTour(false);
                }
            } else {
                endTourOne();
            }
        } else {
            // status in running -> means tour is active.
            if (answerAreaRef.current && status === STATUS.RUNNING) {
                setTimeout(() => {
                    adjustScroll(isTabTour);
                }, 1000);
            }
        }
    };

    const adjustScroll = (isTabTour: boolean) => {
        if (answerAreaRef.current && chatResponseAreaRef.current) {
            answerAreaRef.current.scrollTop = isTabTour
                ? chatResponseAreaRef.current.offsetTop - answerAreaRef.current.offsetTop - 50
                : answerAreaRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if (activateAnswerTour || isLastAnswer) {
            // need to update the tour guide to point to last question.
            setTourOne(prevState => ({
                ...prevState,
                run: false,
                steps: [
                    {
                        content: i18next.t(`appTour.answerTour.shareIcon`),
                        target: `#ShareIcon${lastQuestionIndexValue}`,
                        disableBeacon: true,
                        floaterProps: {
                            disableAnimation: true,
                            autoOpen: true
                        }
                    },
                    {
                        content: i18next.t(`appTour.answerTour.likeIcon`),
                        target: `#LikeIcon${lastQuestionIndexValue}`
                    },
                    {
                        content: i18next.t(`appTour.answerTour.disLikeIcon`),
                        target: `#DisLikeIcon${lastQuestionIndexValue}`
                    }
                ]
            }));
            setTourTwo(prevState => ({
                ...prevState,
                run: false,
                steps: [
                    {
                        content: i18next.t(`appTour.answerTour.faqTabInfo`),
                        target: `[data-tour-id="FAQTab${lastQuestionIndexValue}"]`,
                        disableBeacon: true,
                        floaterProps: {
                            disableAnimation: true,
                            autoOpen: true
                        },
                        styles: {
                            tooltip: {
                                width: "200px",
                                maxWidth: "200px"
                            }
                        }
                    },
                    {
                        content: i18next.t(`appTour.answerTour.manualsTabInfo`),
                        target: `[data-tour-id="ManualsTab${lastQuestionIndexValue}"]`
                    }
                ]
            }));
        }

        if (isStreaming === false && activateAnswerTour) {
            // scroll to last answer
            if (userInfo.answerTourDone === "false" && userInfo.answerTabTourDone === "false") {
                adjustScroll(false);
                setTimeout(() => {
                    setTourOne(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            } else if (userInfo.answerTourDone === "true" && userInfo.answerTabTourDone === "false") {
                adjustScroll(true);
                setTimeout(() => {
                    setTourTwo(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            } else {
                // tour was activate using btn click when all the tours are done.
                adjustScroll(false);
                setTimeout(() => {
                    setTourOne(prevState => ({
                        ...prevState,
                        run: true
                    }));
                }, 2500);
            }
        }

        if (isStreaming === false && !activateAnswerTour) {
            const isAnswerPrivateDlgActive = localStorage.getItem("private_warning") === "true" && localStorage.getItem("show_warning") === "true";
            if (!isAnswerPrivateDlgActive) {
                if (userInfo.answerTourDone === "false" && userInfo.answerTabTourDone === "false") {
                    adjustScroll(false);
                    setTimeout(() => {
                        setTourOne(prevState => ({
                            ...prevState,
                            run: true
                        }));
                    }, 2500);
                } else if (userInfo.answerTourDone === "true" && userInfo.answerTabTourDone === "false") {
                    adjustScroll(true);
                    setTimeout(() => {
                        setTourTwo(prevState => ({
                            ...prevState,
                            run: true
                        }));
                    }, 2500);
                }
            }
        }
    }, [lastQuestionIndexValue, isStreaming, activateAnswerTour, isLastAnswer]);

    function copyToClipboard(href: string) {
        // Add UTM parameters to the chat share link
        const url = new URL(href);
        url.searchParams.set("utm_source", "aiworks");
        url.searchParams.set("utm_medium", "share_link");
        url.searchParams.set("utm_campaign", "chat_share");
        const chatShareLink = url.toString();
        const tempInput = document.createElement("input");
        tempInput.value = chatShareLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notify = () =>
        dispatchToast(
            <Toast style={{ backgroundColor: "white", borderRadius: "5px", color: "green", width: "300px" }}>
                <ToastTitle style={{ color: "black", fontWeight: "bold" }}>{i18next.t("answer.chatLinkCopied")}</ToastTitle>
            </Toast>,
            { intent: "success" }
        );
    const saveSharedChatValue = async (sessionValue: string) => {
        try {
            await saveSharedChatValueApi(sessionValue, userInfo.corp_id)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    console.log(result);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function parseQueryParams(queryvalue: string) {
        const queryparameter = new URLSearchParams(queryvalue);
        const params: { [key: string]: string } = {};

        for (const [key, value] of queryparameter.entries()) {
            params[key] = value;
        }

        return params;
    }
    const condition = faqFeedbacks["category"] && faqFeedbacks["category"] === 2;
    useEffect(() => {
        if (condition) {
            displayLink ? displayLink() : null;
        }
    }, [answer]);

    const validateProductKeyAvailableForHistoryView = (appTypeValue: AppType, newtabIndex: string) => {
        if ((appTypeValue === "FAQ" && faqSanitizedAnswerHtml !== "") || (appTypeValue === "Manuals" && manualSanitizedAnswerHtml !== "")) {
            setTabIndex(newtabIndex);
            return true;
        } else {
            // if any answer is empty.
            let targetKeyValue: string | undefined = "";
            let targetKeyType: string | undefined = "";
            if (appTypeValue === "FAQ" && faqSanitizedAnswerHtml === "") {
                targetKeyValue = selectedProduct?.manual_key;
                targetKeyType = "Manuals";
            }

            if (appTypeValue === "Manuals" && manualSanitizedAnswerHtml === "") {
                targetKeyValue = selectedProduct?.faq_key;
                targetKeyType = "FAQ";
            }

            const selectedLocale = sessionStorage.getItem("selectedLocale") ?? "ja";
            const filteredSelectedProduct = appInfo.product_categories[selectedLocale]?.filter((item: any) =>
                targetKeyType === "FAQ" ? item.faq_key === targetKeyValue : item.manual_key === targetKeyValue
            )[0];
            if (
                (appTypeValue === "FAQ" && filteredSelectedProduct?.faq_key === "") ||
                (appTypeValue === "Manuals" && filteredSelectedProduct?.manual_key === "")
            ) {
                setSelectedProductUnavailbleInfoDlgOpen(true);
                return false;
            } else {
                setSelectedProductUnavailbleInfoDlgOpen(false);
                setTabIndex(newtabIndex);
                return true;
            }
        }
    };

    const handleChangeTabIndex = (event: React.SyntheticEvent, newtabIndex: string) => {
        if (!String(window.location.pathname).includes("/assistant/feedbacks") && (isLoading || isStreaming)) {
            event.preventDefault();
        } else {
            if (currentPageView === "chat") {
                // for chat view or new chat -> selected product contains both the faqkey value & manual key value on streaming answers or answers object.
                if (
                    (newtabIndex.includes("FAQ") && selectedProduct?.faq_key === "") ||
                    (newtabIndex.includes("Manuals") && selectedProduct?.manual_key === "")
                ) {
                    setSelectedProductUnavailbleInfoDlgOpen(true);
                    return;
                }
                setTabIndex(newtabIndex);
            } else {
                // page view is history.
                // the answers object have only only on key either faq key or manual key for existing answers.
                const appTypeValue = newtabIndex.includes("FAQ") ? "FAQ" : "Manuals";
                validateProductKeyAvailableForHistoryView(appTypeValue, newtabIndex);
            }
        }
    };

    const LikeComponentDisplay: React.FC<LikeComponentProps> = ({ isLikePopupVisible, isHistory, feedbackText, hideLikePopup }) => {
        if (!isLikePopupVisible || isHistory) {
            return null;
        }

        return (
            <Layer>
                <Popup className="popupStylesRoot" role="dialog" aria-modal="true" onDismiss={hideLikePopup}>
                    <Overlay onClick={hideLikePopup} />
                    <FocusTrapZone>
                        <div role="document" className="popupStylesContent">
                            <div>
                                <IconButton style={{ color: "green" }} iconProps={{ iconName: "LikeSolid" }} />
                                <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.chatFeedback")}</span>
                                <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideLikePopup} />
                            </div>
                            <div>
                                <TextField className="popupStylesThumbTextBox" multiline resizable={false} value={feedbackText} />
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        );
    };

    const DisLikeComponentDisplay: React.FC<DisLikeComponentProps> = ({
        isDisLikePopupVisible,
        isHistory,
        feedbackText,
        hideDisLikePopup,
        selectedOptions
    }) => {
        if (!isDisLikePopupVisible || isHistory) {
            return null;
        }

        return (
            <Layer>
                <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideDisLikePopup}>
                    <Overlay onClick={hideDisLikePopup} />
                    <FocusTrapZone>
                        <div role="document" className={popupStyles.content}>
                            <div>
                                <IconButton style={{ color: "red" }} iconProps={{ iconName: "DisLikeSolid" }} />
                                <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.chatFeedback")}</span>
                                <IconButton style={{ color: "green", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideDisLikePopup} />
                            </div>
                            <div>
                                <TextField className={popupStyles.thumbTextBox} multiline resizable={false} autoAdjustHeight value={feedbackText} />
                                <Stack tokens={stackTokens} style={{ padding: "5px 10px 10px 15px" }}>
                                    <Checkbox label={i18next.t("answer.notTrue")} checked={selectedOptions === 1} disabled={true} />
                                    <Checkbox label={i18next.t("answer.notHelpful")} checked={selectedOptions === 2} disabled={true} />
                                    <Checkbox label={i18next.t("answer.harmful")} checked={selectedOptions === 3} disabled={true} />
                                </Stack>
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        );
    };

    const ShareComponent: React.FC<ShareProps> = ({ isSharChatPopupVisible }) => {
        if (!isSharChatPopupVisible) {
            return null;
        }
        return (
            <Layer>
                <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideShareChatPopup}>
                    <FocusTrapZone>
                        <div role="document" className={popupStyles.content} style={{ width: "500px" }}>
                            <div style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                <Icon iconName="share" style={{ padding: "5px", fontSize: "20px" }} />
                                <span style={{ fontSize: "19px" }}>{i18next.t("answer.shareChat")}</span>
                                <IconButton style={{ color: "black", float: "right" }} iconProps={{ iconName: "Cancel" }} onClick={hideShareChatPopup} />
                            </div>
                            <div className={styles.thumbTextBox} style={{ display: "grid", justifyContent: "center", width: "465px" }}>
                                <label style={{ fontSize: "20px" }}>{i18next.t("answer.shareChatText")}</label>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <DefaultButton
                                    autoFocus
                                    iconProps={{ iconName: "link" }}
                                    style={{ color: "#1976d2", borderRadius: "5px", padding: "5px", fontSize: "13px" }}
                                    onClick={() => {
                                        const queryParams = parseQueryParams(location.search);
                                        const sessionValue = queryParams.SessionId;
                                        copyToClipboard(window.location.href);
                                        hideShareChatPopup();
                                        notify();
                                        saveSharedChatValue(sessionValue);
                                    }}
                                    text={i18next.t("answer.copyLink")}
                                ></DefaultButton>{" "}
                            </div>
                        </div>
                    </FocusTrapZone>
                </Popup>
            </Layer>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {isLastAnswer && (
                <React.Fragment>
                    <Joyride
                        continuous
                        steps={tourOne.steps}
                        callback={data => {
                            if (!isStreaming) {
                                handleJoyrideCallback(data, false);
                            }
                        }}
                        run={tourOne.run}
                        hideCloseButton
                        scrollToFirstStep
                        showSkipButton
                        disableCloseOnEsc
                        disableOverlayClose
                        spotlightPadding={1}
                        locale={{
                            next: i18next.t(`appTour.next`),
                            skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                            back: i18next.t(`appTour.back`),
                            last: i18next.t(`appTour.last`)
                        }}
                    />
                    <Joyride
                        continuous
                        steps={tourTwo.steps}
                        callback={data => {
                            if (!isStreaming) {
                                handleJoyrideCallback(data, true);
                            }
                        }}
                        run={tourTwo.run}
                        hideCloseButton
                        scrollToFirstStep
                        showSkipButton
                        disableCloseOnEsc
                        disableOverlayClose
                        spotlightPadding={1}
                        locale={{
                            next: i18next.t(`appTour.next`),
                            skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                            back: i18next.t(`appTour.back`),
                            last: i18next.t(`appTour.last`)
                        }}
                    />
                </React.Fragment>
            )}
            <Stack
                className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                verticalAlign="space-between"
                style={doctype ? { backgroundColor: "rgb(240, 255, 240)" } : {}}
            >
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <div>
                            <AnswerIcon
                                language={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.language}
                                product={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.product_filter}
                                sub_product={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.sub_product_filter}
                                documenttype={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.document_category}
                                chatModel={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.chat_model}
                                feedbackCorpId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.feedback_corp_id}
                                feedbackUser={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.feedback_user}
                                CorpId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.corpId}
                                UserId={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.userId}
                                isFeedbackPage={isFeedbackPage}
                                productVersion={(selectedAppType === "FAQ" ? answer : manualsAnswer)?.choices[0].context.productVersion}
                            />
                            {doctype && <LockIcon />}
                        </div>
                        <Box sx={{ width: "80%", typography: "body1" }}>
                            <TabContext value={tabIndex} key={primaryId}>
                                <Box>
                                    <TabList onChange={handleChangeTabIndex} aria-label="lab API tabs example">
                                        <Tab
                                            key={"FAQ" + primaryId}
                                            data-tour-id={`FAQTab${primaryId}`}
                                            label={i18next.t("chat.faq")}
                                            value={"FAQ" + primaryId}
                                            className={styles["tab-root"]}
                                            onClick={() => {
                                                if (currentPageView === "chat") {
                                                    if (selectedProduct?.faq_key === "") {
                                                        setSelectedProductUnavailbleInfoDlgOpen(true);
                                                        return;
                                                    }
                                                } else {
                                                    // history
                                                    const flag = validateProductKeyAvailableForHistoryView("FAQ", "FAQ" + primaryId);
                                                    if (!flag) {
                                                        return;
                                                    }
                                                }
                                                if (!String(window.location.pathname).includes("/assistant/feedbacks")) {
                                                    if (isLoading || isStreaming) {
                                                        return;
                                                    } else {
                                                        if (faqSanitizedAnswerHtml === "") {
                                                            onSend("FAQ");
                                                            activeTab = "FAQ" + primaryId;
                                                        }
                                                        setSelectedAppType("FAQ");
                                                    }
                                                } else {
                                                    setSelectedAppType("FAQ");
                                                }
                                                onTabClicked();
                                            }}
                                        />
                                        <Tab
                                            key={"Manuals" + primaryId}
                                            data-tour-id={`ManualsTab${primaryId}`}
                                            label={i18next.t("chat.manuals")}
                                            value={"Manuals" + primaryId}
                                            className={styles["tab-root"]}
                                            onClick={() => {
                                                if (currentPageView === "chat") {
                                                    if (selectedProduct?.manual_key === "") {
                                                        setSelectedProductUnavailbleInfoDlgOpen(true);
                                                        return;
                                                    }
                                                } else {
                                                    // history
                                                    const flag = validateProductKeyAvailableForHistoryView("Manuals", "Manuals" + primaryId);
                                                    if (!flag) {
                                                        return;
                                                    }
                                                }
                                                if (!String(window.location.pathname).includes("/assistant/feedbacks")) {
                                                    if (isLoading || isStreaming) {
                                                        return;
                                                    } else {
                                                        if (manualSanitizedAnswerHtml === "") {
                                                            onSend("Manuals");
                                                            activeTab = "Manuals" + primaryId;
                                                        }
                                                        setSelectedAppType("Manuals");
                                                    }
                                                } else {
                                                    setSelectedAppType("Manuals");
                                                }
                                                onTabClicked();
                                            }}
                                        />
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                        {["FAQ", "Manuals"].map(tabKey => {
                            const isFaq = tabKey === "FAQ";
                            const tabValue = tabKey + primaryId;
                            const parsedAnswer = isFaq ? faqParsedAnswer : manualParsedAnswer;
                            const selectedAnswer = isFaq ? answer : manualsAnswer;

                            return (
                                tabIndex === tabValue && (
                                    <Box key={tabValue} sx={{ display: "inline-flex" }}>
                                        {isAdmin && (
                                            <IconButton
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "Lightbulb" }}
                                                title={i18next.t("answer.thoughtProcess")}
                                                ariaLabel={i18next.t("answer.thoughtProcess")}
                                                onClick={() => onThoughtProcessClicked()}
                                                disabled={!selectedAnswer?.choices[0].context.thoughts?.length}
                                            />
                                        )}
                                        {parsedAnswer?.citations?.length > 0 && (
                                            <IconButton
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "ClipboardList" }}
                                                title={i18next.t("answer.supportingContent")}
                                                ariaLabel={i18next.t("answer.supportingContent")}
                                                onClick={() => onSupportingContentClicked()}
                                                disabled={!selectedAnswer?.choices[0].context.data_points?.length}
                                            />
                                        )}
                                    </Box>
                                )
                            );
                        })}
                    </Stack>
                </Stack.Item>
                <TabContext value={tabIndex} key={primaryId}>
                    <TabPanel value={"FAQ" + primaryId} sx={{ padding: 0 }} key={"FAQ" + primaryId}>
                        {!String(window.location.pathname).includes("/assistant/feedbacks") &&
                        selectedProduct?.faq_key === "" &&
                        faqSanitizedAnswerHtml === "" ? (
                            <Text style={{ color: "#1976d2" }}>{i18next.t("answer.documentUnavailable")}</Text>
                        ) : isLoading && faqSanitizedAnswerHtml === "" && lastAnswerIndexRefValue === Number(primaryId) ? (
                            <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ width: "100%" }} />
                                <Skeleton animation="wave" sx={{ width: "90%" }} />
                                <Skeleton animation="wave" sx={{ width: "80%" }} />
                                <Skeleton animation="wave" sx={{ width: "70%" }} />
                                <Skeleton animation="wave" sx={{ width: "60%" }} />
                                <Skeleton animation="wave" sx={{ width: "50%" }} />
                            </Box>
                        ) : faqSanitizedAnswerHtml === "" && lastAnswerIndexRefValue !== Number(primaryId) ? (
                            <Box sx={{ width: "100%", marginTop: "5%", display: "flex", flexDirection: "row" }}>
                                <Text style={{ marginTop: "4px", color: "#1976d2" }}>{i18next.t("answer.regenerateResponseInfoText")}</Text>
                                <IconButton
                                    disabled={isLoading || isStreaming}
                                    style={{ color: "#1976d2", float: "right" }}
                                    iconProps={{ iconName: "Sync" }}
                                    onClick={() => {
                                        onSend("FAQ");
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <Stack.Item grow style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
                                    <ReactMarkdown children={faqSanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
                                    {!!faqParsedAnswer.citations.length && answer?.choices[0].context?.app_type && (
                                        <Stack.Item>
                                            <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                                <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                                                {faqParsedAnswer.citations.map((x, i) => {
                                                    const path = getCitationFilePath(x);
                                                    return (
                                                        <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path, i)}>
                                                            {`${++i}. ${x.replace(/^[^/]*\//, "faq/")}`}
                                                        </a>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack.Item>
                                    )}
                                    {answer?.choices[0].context?.has_question_id && faqParsedAnswer && faqParsedAnswer.citations.length === 0 && (
                                        <Text style={{ color: "#1976d2" }}>{i18next.t("chat.appWarningInfoFAQ")}</Text>
                                    )}

                                    {!!faqFollowupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                                        <Stack.Item>
                                            <Stack
                                                horizontal
                                                wrap
                                                className={`${!!faqParsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                                                tokens={{ childrenGap: 6 }}
                                            >
                                                <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.faqFollowupQuestions")}:</span>
                                                {faqFollowupQuestions.map((x: any, i: any) => {
                                                    return (
                                                        <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                                            {`${x}`}
                                                        </a>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack.Item>
                                    )}
                                    {faqFeedbacks["status"] && faqFeedbacks["status"] === true && (
                                        <Stack.Item>
                                            <Stack horizontal horizontalAlign="end">
                                                <IconButton
                                                    id={`ShareIcon${primaryId}`}
                                                    style={{ color: "black" }}
                                                    iconProps={{ iconName: "Share" }}
                                                    title="Share"
                                                    ariaLabel="ShareChatURL"
                                                    onClick={() => showSharChatPopup()}
                                                />
                                                {faqFeedbacks["category"] === 1 && (
                                                    <IconButton
                                                        id={`LikeIcon${primaryId}`}
                                                        style={{ color: "black" }}
                                                        iconProps={{ iconName: "LikeSolid" }}
                                                        title={i18next.t("answer.good")}
                                                        ariaLabel={i18next.t("answer.good")}
                                                        onClick={() => {
                                                            showLikePopup();
                                                        }}
                                                    />
                                                )}
                                                {faqFeedbacks["category"] === 2 && (
                                                    <IconButton
                                                        id={`DisLikeIcon${primaryId}`}
                                                        style={{ color: "black" }}
                                                        iconProps={{ iconName: "DislikeSolid" }}
                                                        title={i18next.t("answer.bad")}
                                                        ariaLabel={i18next.t("answer.bad")}
                                                        onClick={() => {
                                                            showDisLikePopup();
                                                        }}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack.Item>
                                    )}
                                    {answer?.choices[0].context.feedbacktype != undefined && (
                                        <Stack.Item>
                                            <Stack horizontal horizontalAlign="end">
                                                <IconButton
                                                    id={`ShareIcon${primaryId}`}
                                                    style={{ color: "black" }}
                                                    iconProps={{ iconName: "Share" }}
                                                    title={i18next.t("chat.shareChat")}
                                                    ariaLabel={i18next.t("chat.shareChatURL")}
                                                    onClick={() => showSharChatPopup()}
                                                />
                                                {answer?.choices[0].context.feedbacktype === "Positive" && (
                                                    <IconButton
                                                        id={`LikeIcon${primaryId}`}
                                                        style={{ color: "rgb(161, 159, 157)" }}
                                                        iconProps={{ iconName: "LikeSolid" }}
                                                        title={i18next.t("answer.good")}
                                                        ariaLabel={i18next.t("answer.good")}
                                                    />
                                                )}
                                                {answer?.choices[0].context.feedbacktype === "Negative" && (
                                                    <IconButton
                                                        id={`DisLikeIcon${primaryId}`}
                                                        style={{ color: "rgb(161, 159, 157)" }}
                                                        iconProps={{ iconName: "DislikeSolid" }}
                                                        title={i18next.t("answer.bad")}
                                                        ariaLabel={i18next.t("answer.bad")}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack.Item>
                                    )}
                                </Stack.Item>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel value={"Manuals" + primaryId} sx={{ padding: 0 }} key={"Manuals" + primaryId}>
                        {!String(window.location.pathname).includes("/assistant/feedbacks") &&
                        selectedProduct?.manual_key === "" &&
                        manualSanitizedAnswerHtml === "" ? (
                            <Text style={{ color: "#1976d2" }}>{i18next.t("answer.documentUnavailable")}</Text>
                        ) : isLoading && manualSanitizedAnswerHtml === "" && lastAnswerIndexRefValue === Number(primaryId) ? (
                            <Box sx={{ width: "100%" }}>
                                <Skeleton animation="wave" sx={{ width: "100%" }} />
                                <Skeleton animation="wave" sx={{ width: "90%" }} />
                                <Skeleton animation="wave" sx={{ width: "80%" }} />
                                <Skeleton animation="wave" sx={{ width: "70%" }} />
                                <Skeleton animation="wave" sx={{ width: "60%" }} />
                                <Skeleton animation="wave" sx={{ width: "50%" }} />
                            </Box>
                        ) : manualSanitizedAnswerHtml === "" && lastAnswerIndexRefValue !== Number(primaryId) ? (
                            <Box sx={{ width: "100%", marginTop: "5%", display: "flex", flexDirection: "row" }}>
                                <Text style={{ marginTop: "4px", color: "#1976d2" }}>{i18next.t("answer.regenerateResponseInfoText")}</Text>
                                <IconButton
                                    disabled={isLoading || isStreaming}
                                    style={{ color: "#1976d2", float: "right" }}
                                    iconProps={{ iconName: "Sync" }}
                                    onClick={() => {
                                        onSend("Manuals");
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <Stack.Item grow style={{ wordBreak: "break-word", wordWrap: "break-word" }}>
                                    <ReactMarkdown children={manualSanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
                                </Stack.Item>
                                {!!manualParsedAnswer.citations.length && answer?.choices[0].context?.app_type && (
                                    <Stack.Item>
                                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                            <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                                            {manualParsedAnswer.citations.map((x, i) => {
                                                const path = getCitationFilePath(x);
                                                const contentPath = x
                                                    .replace(/^product-manuals-\d+\//, "product-manuals/")
                                                    .replace(/^product-manuals\/[^\/]+\//, "product-manuals/");
                                                return (
                                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path, i)}>
                                                        {`${++i}. ${contentPath.replace(/^[^/]*\//, "product_manual/")}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {manualsAnswer?.choices[0].context?.has_question_id && manualParsedAnswer && manualParsedAnswer.citations.length === 0 && (
                                    <Text style={{ color: "#1976d2" }}>{i18next.t("chat.appWarningInfoManual")}</Text>
                                )}

                                {!!manualFollowupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                                    <Stack.Item>
                                        <Stack
                                            horizontal
                                            wrap
                                            className={`${!!manualParsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                                            tokens={{ childrenGap: 6 }}
                                        >
                                            <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.faqFollowupQuestions")}:</span>
                                            {manualFollowupQuestions.map((x: any, i: any) => {
                                                return (
                                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                                        {`${x}`}
                                                    </a>
                                                );
                                            })}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {manualFeedbacks["status"] && manualFeedbacks["status"] === true && (
                                    <Stack.Item>
                                        <Stack horizontal horizontalAlign="end">
                                            <IconButton
                                                id={`ShareIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "Share" }}
                                                title="Share"
                                                ariaLabel="ShareChatURL"
                                                onClick={() => showSharChatPopup()}
                                            />
                                            {manualFeedbacks["category"] === 1 && (
                                                <IconButton
                                                    id={`LikeIcon${primaryId}`}
                                                    style={{ color: "black" }}
                                                    iconProps={{ iconName: "LikeSolid" }}
                                                    title={i18next.t("answer.good")}
                                                    ariaLabel={i18next.t("answer.good")}
                                                    onClick={() => {
                                                        showLikePopup();
                                                    }}
                                                />
                                            )}
                                            {manualFeedbacks["category"] === 2 && (
                                                <IconButton
                                                    id={`DisLikeIcon${primaryId}`}
                                                    style={{ color: "black" }}
                                                    iconProps={{ iconName: "DislikeSolid" }}
                                                    title={i18next.t("answer.bad")}
                                                    ariaLabel={i18next.t("answer.bad")}
                                                    onClick={() => {
                                                        showDisLikePopup();
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                    </Stack.Item>
                                )}
                                {manualsAnswer?.choices[0].context.feedbacktype != undefined && (
                                    <Stack.Item>
                                        <Stack horizontal horizontalAlign="end">
                                            <IconButton
                                                id={`ShareIcon${primaryId}`}
                                                style={{ color: "black" }}
                                                iconProps={{ iconName: "Share" }}
                                                title={i18next.t("chat.shareChat")}
                                                ariaLabel={i18next.t("chat.shareChatURL")}
                                                onClick={() => showSharChatPopup()}
                                            />
                                            {manualsAnswer?.choices[0].context.feedbacktype === "Positive" && (
                                                <IconButton
                                                    id={`LikeIcon${primaryId}`}
                                                    style={{ color: "rgb(161, 159, 157)" }}
                                                    iconProps={{ iconName: "LikeSolid" }}
                                                    title={i18next.t("answer.good")}
                                                    ariaLabel={i18next.t("answer.good")}
                                                />
                                            )}
                                            {manualsAnswer?.choices[0].context.feedbacktype === "Negative" && (
                                                <IconButton
                                                    id={`DisLikeIcon${primaryId}`}
                                                    style={{ color: "rgb(161, 159, 157)" }}
                                                    iconProps={{ iconName: "DislikeSolid" }}
                                                    title={i18next.t("answer.bad")}
                                                    ariaLabel={i18next.t("answer.bad")}
                                                />
                                            )}
                                        </Stack>
                                    </Stack.Item>
                                )}
                            </>
                        )}
                    </TabPanel>
                </TabContext>
                <ShareComponent isSharChatPopupVisible={isSharChatPopupVisible} />
                <Toaster toasterId={toasterId} position="bottom-start" pauseOnHover pauseOnWindowBlur timeout={2000} />
                {tabIndex === "FAQ" + primaryId && (
                    <>
                        <LikeComponentDisplay
                            isLikePopupVisible={isLikePopupVisible}
                            isHistory={isHistory}
                            feedbackText={faqFeedbacks["data"] && faqFeedbacks["data"]["text"]}
                            hideLikePopup={hideLikePopup}
                        />
                        <DisLikeComponentDisplay
                            isDisLikePopupVisible={isDisLikePopupVisible}
                            isHistory={isHistory}
                            feedbackText={faqFeedbacks["data"] && faqFeedbacks["data"]["text"]}
                            hideDisLikePopup={hideDisLikePopup}
                            selectedOptions={faqFeedbacks["data"] && faqFeedbacks["data"]["type"]}
                        />
                    </>
                )}
                {tabIndex === "Manuals" + primaryId && (
                    <>
                        <LikeComponentDisplay
                            isLikePopupVisible={isLikePopupVisible}
                            isHistory={isHistory}
                            feedbackText={manualFeedbacks["data"] && manualFeedbacks["data"]["text"]}
                            hideLikePopup={hideLikePopup}
                        />
                        <DisLikeComponentDisplay
                            isDisLikePopupVisible={isDisLikePopupVisible}
                            isHistory={isHistory}
                            feedbackText={manualFeedbacks["data"] && manualFeedbacks["data"]["text"]}
                            hideDisLikePopup={hideDisLikePopup}
                            selectedOptions={manualFeedbacks["data"] && manualFeedbacks["data"]["type"]}
                        />
                    </>
                )}
            </Stack>
            {isLastAnswer && <Text className={styles.lastInfo}>{i18next.t("chat.responseInfo")}</Text>}
            {selectedProductUnavailbleInfoDlgOpen && (
                <SelectedProductUnAvailableInfoMsgDialog
                    showWarningFlag={selectedProductUnavailbleInfoDlgOpen}
                    warningMsg={
                        selectedAppType !== "FAQ"
                            ? i18next.t("answer.faqUnavailableForSelectedProduct")
                            : i18next.t("answer.manualsUnavailableForSelectedProduct")
                    }
                    onClose={() => setSelectedProductUnavailbleInfoDlgOpen(false)}
                />
            )}
        </div>
    );
};
